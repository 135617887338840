<template>
  <div class="yujianfenzhen-form">
    <van-form @submit="onSubmit" colon :scroll-to-error="true" :disabled="isDetail" >
      <h3 class="title">基本信息</h3>
      <van-field name="name" label="患者姓名">
        <template #input>
          {{name}}
        </template>
      </van-field>
      <van-field name="temperature" label="体温℃" :rules="[{ required: true, message: '请选择体温' }]">
        <template #input>
          <van-radio-group v-model="temperature" direction="horizontal" :disabled="isDetail">
            <van-radio v-for="item in temperatureList" :key="item.value" :name="item.value">{{item.name}}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="tel" label="联系电话">
        <template #input>
          {{tel}}
        </template>
      </van-field>
      <van-field name="time" label="日期">
        <template #input>
          {{time}}
        </template>
      </van-field>
      <van-field name="idCard" label="身份证号">
        <template #input>
          {{idCard}}
        </template>
      </van-field>
      <div class="tip">
        <van-icon name="warning-o" color="#952f2fe6" size="14" />
        <span decode='true'>&nbsp;请仔细阅读以下选项，符合的打&nbsp;</span>
        <van-icon name="success" color="#952f2fe6" size="14" />
      </div>
      <h3 class="title">流行病史 </h3>
      <van-field name="lxbs" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-checkbox-group v-model="lxbs" @change="checkChange('lxbs')" :disabled="isDetail">
            <van-checkbox v-for="item in lxbsList" :key="item.value" :name="item.value">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <h3 class="title">接触史 </h3>
      <van-field name="jcs" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-checkbox-group v-model="jcs" @change="checkChange('jcs')" :disabled="isDetail">
            <van-checkbox v-for="item in jcsList" :key="item.value" :name="item.value">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <h3 class="title">高风险岗位人员（每周至少测核酸一次） </h3>
      <van-field name="highRisk" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-checkbox-group v-model="highRisk" @change="checkChange('highRisk')" :disabled="isDetail">
            <van-checkbox v-for="item in highRiskList" :key="item.value" :name="item.value">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <h3 class="title">核酸检测 </h3>
      <van-field
        v-model="frequency"
        name="frequency"
        label="频次"
        placeholder="频次"
      />
      <van-field
        readonly
        clickable
        name="lastTime"
        :value="lastTime"
        label="最近一次"
        placeholder="点击选择日期"
        @click="showLastTime = true"
      />
      <van-calendar v-model="showLastTime" :show-confirm="false" :max-date="maxDate" :min-date="minDate" @confirm="onLastConfirm" />
      <h3 class="title">十大临床症状 </h3>
      <van-field name="tenClinicalSymptom" :rules="[{ required: true, message: '请选择' }]">
        <template #input>
          <van-checkbox-group v-model="tenClinicalSymptom" direction="horizontal" @change="checkChange('tenClinicalSymptom')" :disabled="isDetail">
            <van-checkbox v-for="item in tenClinicalSymptomList" :key="item.value" :name="item.value" :checked="item.checked">{{item.name}}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <h3 class="title">新冠疫苗接种情况 </h3>
      <van-field
        v-model="inoculation"
        name="inoculation"
        label="接种（针）"
        placeholder="接种（针）"
      />
      <van-field
        v-model="total"
        name="total"
        label="总共（针）"
        placeholder="总共（针）"
      />
      <van-field
        readonly
        clickable
        name="latelyTime"
        :value="latelyTime"
        label="最近一次"
        placeholder="点击选择日期"
        @click="showCalendar = true"
      />
      <van-calendar v-model="showCalendar" :show-confirm="false" :max-date="maxDate" :min-date="minDate" @confirm="onConfirm" />

      <h3 class="title">特别提醒 </h3>
      <div class="page-section">
        <div class="page-section-title">根据《传染病防治法》《治安管理处罚法》等法律规定，如果您隐瞒上述情况或者拒绝配合医务人员开展调查等处置措施的，将承担相应的法律责任。</div>
      </div>
      <div style="margin: 16px;">
        <van-button v-if="!isDetail" round block type="info" :loading="btnLoading" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'YujianfenzhenForm',
  data () {
    return {
      minDate: new Date(2019, 1, 1),
      maxDate: new Date(),
      temperatureList: [
        { name: '<37.3', value: 1 },
        { name: '≥37.3', value: 2 }
      ],
      lxbsList: [
        { name: '1.有境外及中高风险地区旅居史（28天内）', value: 1 },
        { name: '2.或与境外及中高风险地区人员接触史（21天内）', value: 2 },
        { name: '3.无以上流行病史', value: 0 }
      ],
      jcsList: [
        { name: '1.有国内新冠疫情中高风险地区旅居史人员接触史（21天内）', value: 1 },
        { name: '2.有聚集性发病史（2例以上发热或呼吸道症状病例）（14天内）', value: 2 },
        { name: '3.无以上接触史', value: 0 }
      ],
      highRiskList: [
        { name: '1.医疗机构内的发热门诊、隔离病房、新冠核酸检验等存在高风险暴露的医务人员', value: 1 },
        { name: '2.从事呼吸道标本采集和检测的工作人员', value: 2 },
        { name: '3.国际交通运输工具从业人员', value: 3 },
        { name: '4.船舶引航员等登临外籍船舶作业人员', value: 4 },
        { name: '5.移民、海关以及市场监管系统一线工作人员', value: 5 },
        { name: '6.进口冷链食品（含外包装）监管和从业人员', value: 6 },
        { name: '7.口岸进口货物直接接触人员', value: 7 },
        { name: '8.集中隔离医学观察场所工作人员', value: 8 },
        { name: '9.接驳转运工作人员', value: 9 },
        { name: '10.“快捷通道”工作人员', value: 10 },
        { name: '11.其他经风险研判确定的高风险岗位人员', value: 11 },
        { name: '12.非以上高风险岗位人员', value: 0 }
      ],
      tenClinicalSymptomList: [
        { name: '发热', value: 1 },
        { name: '干咳', value: 2 },
        { name: '鼻塞', value: 3 },
        { name: '流涕', value: 4 },
        { name: '咽痛等呼吸道症状', value: 5 },
        { name: '腹泻等消化道症状', value: 6 },
        { name: '乏力', value: 7 },
        { name: '肌痛', value: 8 },
        { name: '结膜炎', value: 9 },
        { name: '嗅觉味觉减退等其他症状', value: 10 },
        { name: '无以上十大临床症状', value: 0 }
      ],
      lxbs: [],
      jcs: [],
      highRisk: [],
      tenClinicalSymptom: [],
      frequency: '',
      lastTime: '',
      name: '',
      inoculation: '',
      total: '',
      latelyTime: '',
      temperature: '',
      tel: '',
      idCard: '',
      time: '',
      state: 0,
      isDetail: false,
      btnLoading: false,
      showLastTime: false,
      showCalendar: false
    }
  },
  created () {
    const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo) {
      this.userId = userInfo.id
      this.name = userInfo.patientName
      this.tel = userInfo.patientPhone
      this.idCard = userInfo.patientSfzh
    }
    const itemId = this.$route.query && this.$route.query.id
    if (itemId) {
      this.itemId = itemId
      this.isDetail = true
      this.getPrecheckDetail()
    }
    this.time = moment(new Date()).format('yyyy-MM-DD')
  },
  mounted () {
  },
  methods: {
    onSubmit(e) {
      const { temperature, lxbs, jcs, highRisk, tenClinicalSymptom, lastTime, latelyTime, frequency, inoculation, total } = e
      const temperatureList = this.temperatureList.map(item => {
        if (item.value === temperature) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
      this.state = temperature === 1 ? 0 : 1
      const lxbsList = this.repaintList('lxbsList', lxbs)
      const jcsList = this.repaintList('jcsList', jcs)
      const highRiskList = this.repaintList('highRiskList', highRisk)
      const tenClinicalSymptomList = this.repaintList('tenClinicalSymptomList', tenClinicalSymptom)
      const content = JSON.stringify({
        temperature,
        temperatureList,
        lxbsList,
        jcsList,
        highRiskList,
        tenClinicalSymptomList,
        lastTime,
        latelyTime,
        frequency,
        inoculation,
        total
      })
      const obj = {
        content,
        name: this.name,
        patientId: this.userId,
        phone: this.tel,
        sfzh: this.idCard,
        state: this.state
      }
      this.addPrecheck(obj)
    },
    addPrecheck(obj) {
      this.$Apis.precheckAdd(obj).then(res => {
        this.btnLoading = true
        if (res.code === 200) {
          this.$toast('保存成功')
          this.$router.go(0)
        } else {
          this.$toast(res.message)
        }
      }).catch(() => {
        this.btnLoading = false
      })
    },
    getPrecheckDetail() {
      this.$Apis.precheckDetail({ id: this.itemId }).then(res => {
        if (res.code === 200) {
          this.data = res.data
          const data = res.data
          const content = JSON.parse(data.content)
          const { name, phone, sfzh, createTime: time } = data
          const { temperatureList, lxbsList, jcsList, highRiskList, tenClinicalSymptomList, lastTime, latelyTime, temperature, frequency, inoculation, total } = content
          this.time = time
          this.temperature = temperature
          this.temperatureList = temperatureList
          this.lxbsList = lxbsList
          this.lxbs = this.getListValue(lxbsList)
          this.jcsList = jcsList
          this.jcs = this.getListValue(jcsList)
          this.highRiskList = highRiskList
          this.highRisk = this.getListValue(highRiskList)
          this.tenClinicalSymptomList = tenClinicalSymptomList
          this.tenClinicalSymptom = this.getListValue(tenClinicalSymptomList)
          this.lastTime = lastTime
          this.latelyTime = latelyTime
          this.frequency = frequency
          this.inoculation = inoculation
          this.total = total
          this.name = name
          this.tel = phone
          this.idCard = sfzh
        }
      })
    },
    getListValue(list) {
      return list.filter(item => item.checked).map(item => item.value)
    },
    repaintList(name, value) {
      if (this.state === 0) {
        this.state = value.some(item => item > 0) ? 1 : 0
      }
      const list = this[name].map(item => {
        if (value.includes(item.value)) {
          item.checked = true
        } else {
          item.checked = false
        }
        return item
      })
      return list
    },
    checkChange(name) {
      const value = this[name]
      const valueLength = value.length
      const isHas = value.includes(0) // 是否含有0
      if (valueLength > 1) {
        const lastValue = value[value.length - 1] // 最后一位值
        if (lastValue !== 0 && isHas) {
          value.splice(value.indexOf(0), 1)
          this[name] = value
        } else if (isHas) {
          this[name] = [0]
        }
      }
    },
    onConfirm(e) {
      this.showCalendar = false
      this.latelyTime = moment(e).format('yyyy-MM-DD')
    },
    onLastConfirm(e) {
      this.showLastTime = false
      this.lastTime = moment(e).format('yyyy-MM-DD')
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.yujianfenzhen-form{
  padding: .1rem;
  /deep/.van-checkbox{
    padding: .03rem 0;
  }
  .title{
    margin: .16rem 0;
    font-size: .14rem;
    color: #2b77ff;
  }
  .tip {
    font-size: .14rem;
    color: #952f2fe6;
    text-align: center;
    line-height: .2rem;
    margin: .1rem 0;
  }
  .page-section {
    background: #fff;
    margin-bottom: .16rem;
  }

  .page-section-title {
    padding: .08rem .16rem;
    line-height: 1.5;
  }
}
</style>
