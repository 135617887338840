<template>
  <div class="guahaoxiangqing">
    <van-loading v-if="loading" size="24px">加载中...</van-loading>
    <template v-else>
      <div class="guahaoxiangqing-top">
        <div class="pic">
          <img v-if="registerInfo.status === 0" src="~@/assets/images/guahao/cancelIcon.png" alt="">
          <img v-else src="~@/assets/images/guahao/right.png" alt="">
        </div>
        <div class="text">
          <p class="text-status">{{registerInfo.status | status }}</p>
          <p>{{registerInfo.day}}</p>
        </div>
      </div>
      <p class="top-tips">
        <span v-if="registerInfo.status === 0">挂号已作废，如需就诊请重新挂号</span>
        <span v-else><span v-if="registerInfo.status === 2">预约</span>挂号成功，请在预约当天提前15分钟到医院挂号窗口取号后再到就诊科室扫码排队候诊。</span>
      </p>
      <van-cell-group class="mb10">
        <van-cell class="item-val" title="就诊时段" :value="`${registerInfo.startTime}-${registerInfo.endTime}`"/>
      </van-cell-group>
      <van-cell-group>
        <!-- <van-cell class="item-val" title="就诊时段" value="2021-11-27 星期六 下午13:33:33~14:15:15"/> -->
        <van-cell title="挂号类型" :value="registerInfo.registerType == 1 ? '预约挂号' : (registerInfo.registerType == 2 ? '当天挂号' : '未知')" />
        <van-cell title="医院名称" value="常州瑞鸿医院" />
        <van-cell title="就诊科室" :value="registerInfo.deptName" />
        <van-cell title="医生名称" :value="registerInfo.doctorName || '——'" />
        <van-cell title="医生职称" :value="registerInfo.titleName || '——'" />
        <van-cell title="就诊人" :value="registerInfo.patientName" />
        <van-cell class="item-val" title="交易金额" :value="`￥${registerInfo.money}`" />
        <van-cell v-if="registerInfo.cancelTime" title="取消时间" :value="registerInfo.cancelTime" />
        <van-cell v-if="registerInfo.remarks" title="取消原因" :value="registerInfo.remarks" />
      </van-cell-group>
      <div class="bottom-btn">
        <van-button class="bottom-btn-style" v-if="registerInfo.status === 2" @click="showDialog = true">取消挂号</van-button>
        <van-button class="bottom-btn-style" type="info" @click="toHome">返回首页</van-button>
      </div>
    </template>
    <van-dialog v-model="showDialog" title="提示" show-cancel-button @confirm="registerCancel">
      <van-field
        v-model="remarks"
        rows="3"
        autosize
        type="textarea"
        placeholder="请输入取消原因"
      />
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: 'GuaHaoXiangQing',
  filters: {
    status: (val) => {
      switch (val) {
        case 0:
          return '预约挂号已作废'
        case 1:
          return '挂号成功'
        default:
          return '预约挂号成功'
      }
    }
  },
  data () {
    return {
      showDialog: false,
      loading: false,
      id: '',
      remarks: '',
      showButton: false,
      registerInfo: {}
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getRegisterDetail()
  },
  mounted () {},
  methods: {
    getRegisterDetail() {
      this.loading = true
      const id = this.id
      this.$Apis.registerDetail({ id }).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.registerInfo = res.data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    registerCancel() {
      const parms = {
        remarks: this.remarks,
        id: this.registerInfo.id
      }
      this.$Apis.registerCancel(parms).then(res => {
        if (res.code === 200) {
          this.$toast('取消成功')
          this.$router.go(-1)
        } else {
          this.$toast(res.message)
        }
      })
    },
    toHome() {
      this.$router.push({
        path: '/',
        replace: true
      })
      // this.$router.replace('/')
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.guahaoxiangqing{
  /deep/.van-loading{
    margin-top: 1rem;
    text-align: center;
  }
  .item-val{
    /deep/.van-cell__value {
      color:#FCA237;
    }
  }
  .mb10{
    margin-bottom: .1rem;
  }
  &-top{
    background: #fff;
    padding: .1rem;
    display: flex;
    .pic{
      margin: 0 .1rem 0 0;
      width: .4rem;
      height: .4rem;
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .text{
      font-size: .14rem;
      line-height: 1.3;
      color: #999;
      &-status{
        color: #1777FF;
      }
    }
  }
  .top-tips{
    padding: .02rem 0 .15rem .1rem;
    background: #fff;
    margin-bottom: .1rem;
    line-height: 1.5;
    color: #666;
  }
  .bottom-btn{
    padding-bottom: .2rem;
    &-style{
      display: block;
      margin: .2rem auto .1rem;
      width:90%;
      border-radius: .2rem;
    }
  }
}
</style>
