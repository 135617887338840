<template>
  <div class="yujianfenzhen">
    <div v-if="showForm" class="page" :class="isLogin ? 'no-roll' : ''">
      <YujianfenzhenForm />
    </div>
    <YujianfenzhenItem v-else :list="listData" />
  </div>
</template>
<script>
import moment from 'moment'
import YujianfenzhenItem from './components/yujianfenzhenItem.vue'
import YujianfenzhenForm from './components/yujianfenzhenForm.vue'
export default {
  name: 'Yujianfenzhen',
  components: {
    YujianfenzhenItem,
    YujianfenzhenForm
  },
  data () {
    return {
      listData: [],
      isLogin: false,
      userId: '',
      id: '',
      time: ''
    }
  },
  computed: {
    showForm: function () {
      if (this.itemId) {
        return true
      } else {
        if (this.listData.length === 0) {
          return true
        } else {
          return false
        }
      }
    }
  },
  watch: {
    $route: function() {
      this.$router.go(0)
    }
  },
  created () {
    this.time = moment(new Date()).format('yyyy-MM-DD')
    const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))
    const itemId = this.$route.query && this.$route.query.id
    if (itemId) {
      this.itemId = itemId
    }
    if (userInfo) {
      this.userId = userInfo.id
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      const obj = {
        patientId: this.userId,
        startTime: `${this.time} 00:00:00`,
        endTime: `${this.time} 23:59:59`
      }
      this.$Apis.precheckGetList(obj).then(res => {
        if (res.code === 200) {
          this.listData = res.data
        }
      })
    },
    toDetail() {}
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
</style>
