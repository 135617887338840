<template>
  <div class="jiaofeimingxi">
    <div class="yaopinmingxi">
      <div class="top-pic" v-if="payStatus">
        <image class="pic" mode="scaleToFill" src="../../images/yijiaofei.png"/>
      </div>
      <div class="list-item">
        <div class="item" v-for="item in list" :key="item.id">
          <div class="name-num">
            <div class="name">{{ item.name }}</div>
            <div class="num">x{{ item.quantity }}</div>
          </div>
          <div class="price">￥{{ item.price }}</div>
        </div>
        <div class="bottom-price">
          <span class="subtotal">小计</span>
          <span class="subtotal-price">￥{{ totalMoney }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'JiaoFeiMingXi',
  data () {
    return {
      id: '',
      list: [],
      payStatus: false,
      totalMoney: ''
    }
  },
  created () {
    this.id = this.$route?.query?.id || ''
    this.getChargeDetailsByClinicNo()
  },
  mounted () {},
  methods: {
    getChargeDetailsByClinicNo() {
      this.$Apis.getChargeDetailsByClinicNo({ clinicNo: this.id }).then(res => {
        if (res.code === 200) {
          this.list = res.data?.chargeDetails
          this.totalMoney = res.data?.totalMoney
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.jiaofeimingxi{
  .yaopinmingxi{
    padding: .18rem;
    background: #F7F7F7;
    position: relative;
  }
  .top-pic{
    position: absolute;
    right: .6rem;
    top: .01rem;
  }
  .top-pic .pic{
    width: 1rem;
    height: .8rem;
  }
  .list-item{
    padding: .13rem;
    background: #fff;
    border-radius: .15rem;
    box-shadow: 0 0 .1rem rgba(0, 0, 0, .2);
  }
  .item{
    border-bottom: .01rem solid #EEEEEE;
    font-size: .14rem;
  }
  .name-num{
    display: flex;
    justify-content: space-between;
    color: #333;
    font-weight: bold;
    margin: .1rem 0;
  }
  .name-num .name{
    line-height: .2rem;
    margin-right: .1rem;
  }
  .name-num .num{
    font-size: .12rem;
  }
  .item .price{
    line-height: .15rem;
    text-align: right;
    color: #FD4F4B;
    margin:.1rem 0 .14rem;
  }
  .bottom-price{
    font-size:.14rem;
    font-weight: bold;
    line-height: .55rem;
    text-align: right;
  }
  .subtotal-price{
    margin-left: .1rem;
    color: #FD4F4B;
  }
}

</style>
