<template>
  <div>
    <my-patient></my-patient>
    <div style="margin: 16px;">
      <van-button round block type="info" @click="handleSearch">查询非就诊人</van-button>
    </div>
  </div>
</template>

<script>
import myPatient from '../myPatient/myPatient.vue'
export default {
  name: '',
  components: {
    myPatient
  },
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  methods: {
    handleSearch() {
      this.$router.push('/hesuanbaogao/search')
    }
  }
}
</script>

<style lang="less" scoped>
  .my-patient-page{
    min-height: 0;
    background-color: inherit;
  }
</style>
