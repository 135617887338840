<template>
  <div>
    <div class="experienceSummary-box">
      <div class="experienceSummary-top">
        <img src="@/assets/images/report/summarize_bg.png" alt="" />
      </div>
      <div class="experienceSummary-title">
        <div>尊敬的{{ detailsSummary.patientName }}您好!</div>
        <div>
          此次您在常州瑞鸿医院进行健康体检 后，经过对您所做项目的结果进行综合分
          析，主检专家给您的体检总结建议如下。
          希望您能够携手专家，科学的管理好自己 的健康!感谢您对医院的信任！
        </div>
      </div>
      <div class="experienceSummary-list-box">
        <ul class="experienceSummary-list">
          <li>
            <div class="experienceSummary-list-top">检查结果</div>
            <div class="experienceSummary-list-bottom">
              <code>
                 {{ detailsSummary.result }}
              </code>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detailsSummary: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.experienceSummary-box {
  position: relative;
  width: 100%;
  .experienceSummary-top {
    margin-top: 16px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .experienceSummary-title {
    position: absolute;
    left: 20px;
    top: 24px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    div:nth-of-type(1) {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      margin-bottom: 15px;
    }
    div:nth-of-type(2) {
      width: 240px;
      word-break: normal;
      font-size: 12px;
      line-height: 22px;
    }
  }
  .experienceSummary-list-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .experienceSummary-list {
    position: absolute;
    top: 205px;
    font-size: 14px;
    background: #fff;
    font-weight: bold;
    border-radius: 5px;
    width: 330px;
    padding: 10px;
    li {
      .experienceSummary-list-top {
        background: #e6f2fe;
        border: 1px dashed #969798;
        padding: 12px 15px;
        font-size: 15px;
        color: #333;
      }
      .experienceSummary-list-bottom {
        background: #ecf5fe;
        border: 1px dashed #969798;
        border-top: 0px;
        padding: 12px 15px;
        font-size: 12px;
        color: #777;
        line-height: 24px;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
