<template>
  <div class="tijian-detail">
    <div v-if="loading" class="loading-wrap">
      <van-loading size="24px" color="#0094ff" vertical>体检结果正在加载,请稍后...</van-loading>
    </div>
    <div v-else class="header">
      <ul class="header-top">
        <li
          v-for="(item, index) in classify"
          :key="index"
          @click="classification(index)"
          :class="titleIndex === index ? 'action' : ''"
        >
          <div>
            <img
              :src="titleIndex === index ? item.imageUrlSelect : item.imageUrl"
              alt=""
            />
          </div>
          <div>{{ item.title }}</div>
          <div v-show="titleIndex === index">
            <img src="@/assets/images/report/triangle.png" alt="" />
          </div>
          <div class="size_icon" v-show="index === 0">
            <img src="@/assets/images/report/size_bg.png" alt="" />
          </div>
          <div v-show="index === 0" class="size">{{ detailsAbnormal.num }}</div>
        </li>
      </ul>
    </div>
    <Aomaly v-show="titleIndex === 0" :detailsAbnormal="detailsAbnormal" />
    <CheckAll v-show="titleIndex === 1" :detailsAll="detailsAll" :cardNo="detailsAbnormal.cardNo" :disabledBtn="disabledBtn"  />
    <ExperienceSummary v-show="titleIndex === 2" :detailsSummary="detailsSummary" />
  </div>
</template>

<script>
import Aomaly from './physicalExamination/anomaly.vue'
import CheckAll from './physicalExamination/checkAll.vue'
import ExperienceSummary from './physicalExamination/experienceSummary.vue'
export default {
  name: 'TiJianDetail',
  components: {
    Aomaly,
    CheckAll,
    ExperienceSummary
  },
  data() {
    return {
      id: '',
      cardNo: '',
      clinicCode: '',
      titleIndex: 1,
      disabledBtn: true,
      classify: [
        {
          value: 0,
          title: '异常项',
          imageUrl: require('@/assets/images/report/anomaly_01.png'),
          imageUrlSelect: require('@/assets/images/report/anomaly_02.png'),
        },
        {
          value: 1,
          title: '全部项',
          imageUrl: require('@/assets/images/report/reportAll_01.png'),
          imageUrlSelect: require('@/assets/images/report/reportAll_02.png'),
        },
        {
          value: 2,
          title: '体检结果',
          imageUrl: require('@/assets/images/report/summarize_01.png'),
          imageUrlSelect: require('@/assets/images/report/summarize_02.png'),
        },
      ],
      linkUrl: "",
      detailsAbnormal: {},
      detailsAll: {},
      detailsSummary: {},
      loading: false
    }
  },
  created() {
    const { cardNo, clinicCode, id } = this.$route.query
    this.id = id
    this.cardNo = cardNo
    this.clinicCode = clinicCode
    this.getDetail()
  },
  mounted() {},
  methods: {
    classification(index) {
      this.titleIndex = index
    },
    getDetail() {
      this.loading = true
      const parms = {
        patientId: this.id,
        cardNo: this.cardNo,
        clinicCode: this.clinicCode,
      }
      this.$Apis.ruihongGetCzrhRegisterInfoDetails(parms).then((res) => {
        this.loading = false
        this.disabledBtn = false
        if (res.code === 200) {
          this.linkUrl = res.data.tjUrl
          this.detailsAbnormal = res.data.detailsAbnormal
          this.detailsAll = res.data.detailsAll
          this.detailsSummary = res.data.detailsSummary
        } else {
          if (res.message) {
            this.$toast.fail(res.message)
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.tijian-detail{
  padding: .1rem 0 0 0;
  min-height: calc(100vh - .2rem);
  .header-top {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 15px;
    .action {
      background: linear-gradient(180deg, #1789ff 1%, #4ea6ff 99%);
      div:nth-of-type(2) {
        color: #fff;
      }
    }
    li {
      margin: 0 auto;
      padding: 8px 22px;
      border-radius: 10px;
      background: #fff;
      position: relative;
      color: #333;
      .size_icon {
        position: absolute;
        top: 0;
        right: 0;
      }
      .size {
        position: absolute;
        top: 3px;
        right: 12px;
        color: #fff;
        font-size: 10px;
      }
      div:nth-of-type(1) {
        margin: 0 auto;
        width: 23px;
        height: 23px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      div:nth-of-type(2) {
        font-size: 14px;
        color: #333;
        margin-top: 5px;
      }
      div:nth-of-type(3) {
        font-size: 14px;
        color: #333;
        margin-top: 5px;
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%);
      }
    }
  }
}
.loading-wrap{
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
