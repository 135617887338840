<template>
  <div class="reportList">
    <div class="reportList-box">
      <div
        class="reportList-content"
        v-for="(item, index) in setList"
        :key="index"
        :style="{
          background: item.style.bg,
        }"
        @click="detailsBtn(item)"
      >
        <ul class="reportList-content-title">
          <li>{{ item.operDate }}</li>
          <li>
            <div>已出报告</div>
          </li>
        </ul>
        <ul class="reportList-content-details">
          <li>项目</li>
          <li>{{ item.packageName }}</li>
        </ul>
        <ul class="reportList-content-icon">
          <li></li>
          <li><img :src="item.style.icon" alt="" /></li>
        </ul>
      </div>
    </div>
    <noData v-if="!setList.length && !loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      styleModule: [
        {
          bg: "linear-gradient(180deg,#ff9162 0%, #ff7b6c 100%)",
          icon: require("../../assets/images/report/icon001.png"),
          count: 0,
        },
        {
          bg: "linear-gradient(180deg,#0dc6f2 0%, #08abf2 100%)",
          icon: require("../../assets/images/report/icon002.png"),
          count: 0,
        },
        {
          bg: "linear-gradient(180deg,#879ffe 0%, #5a94ff 100%)",
          icon: require("../../assets/images/report/icon003.png"),
          count: 0,
        },
        {
          bg: "linear-gradient(180deg,#a898fe 0%, #828afb 100%)",
          icon: require("../../assets/images/report/icon004.png"),
          count: 0,
        },
        {
          bg: "linear-gradient(180deg,#5bbaf4 0%, #4485eb 100%)",
          icon: require("../../assets/images/report/icon005.png"),
          count: 0,
        },
      ],
      list: [],
      selected: [],
      setList: [],
      loading: false
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.physicalExamination();
  },
  computed: {},
  methods: {
    // 随机选中样式(每styleModule.length个模块背景色和图标不会重复)
    styleRandom() {
      // 过滤出未被选中过的数组
      let notSelect = this.selected.length
        ? this.styleModule.filter((i) => !this.selected.includes(i.bg))
        : this.styleModule;
      // 模板中的都被选中一遍后 重置选中数组
      if (notSelect.length === 0) {
        this.selected = [];
        notSelect = this.styleModule;
      }
      // 获取当前索引值
      const currentIndex = this.$utils.getRandomInt(0, notSelect.length - 1);
      this.selected.push(notSelect[currentIndex].bg);
      return notSelect[currentIndex];
    },
    // 请求数据的地方
    physicalExamination() {
      // 测试数据
      // this.setList = [
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08 111111",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08 1111",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      //   {
      //     cardNo: "T411113094",
      //     clinicCode: "2303080115",
      //     packageName: "男",
      //     operDate: "2023-03-08",
      //   },
      // ];
      // this.setList.forEach((item) => {
      //   item.style = this.styleRandom();
      // });
      // this.list = this.setList;
      const parms = {
        patientId: this.id,
      };
      this.loading = true
      this.$Apis.ruihongGetCzrhRegisterlnfo(parms).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.setList = res.data;
          this.setList.forEach((item) => {
            item.style = this.styleRandom();
          });
          // forEach以后再赋值到data中
          this.list = this.setList;
        } else {
          if (res.message) {
            this.$toast.fail(res.message);
          }
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 详情页面
    detailsBtn(item) {
      this.$router.push({
        path: "/tijiandetail",
        query: {
          cardNo: item.cardNo,
          clinicCode: item.clinicCode,
          id: this.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.reportList {
  width: 100%;
  padding: 22px 15.5px;
  box-sizing: border-box;
  .reportList-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    .reportList-content {
      min-width: 164px;
      border-radius: 8px;
      margin-bottom: 15.5px;
      padding: 16.5px 12px;
      color: #fff;
      box-sizing: border-box;
      .reportList-content-title {
        display: flex;
        justify-content: space-around;
        margin-bottom: 14px;
        li:nth-of-type(1) {
          font-size: 14px;
          line-height: 22.5px;
          width:84px;
        }
        li:nth-of-type(2) {
          width: 36px;
          height: 36px;
          border: 0.5px dashed #ffffff;
          border-radius: 50%;
          text-align: center;
          line-height: 18px;
          font-size: 12px;
          padding: 4px;
          flex-shrink: 0;
          div {
            width: 26px;
            margin: 0 auto;
          }
        }
      }
      .reportList-content-details {
        li:nth-of-type(1) {
          font-size: 12px;
          margin-bottom: 9px;
          opacity: 0.4;
        }
        li:nth-of-type(2) {
          font-size: 18px;
        }
      }
      .reportList-content-icon {
        display: flex;
        justify-content: space-between;
        li:nth-of-type(1) {
          width: 24px;
          height: 3px;
          border-radius: 1.5px;
          margin-top: 20px;
          opacity: 0.4;
          background: #fff;
        }
        li:nth-of-type(2) {
          width: 54px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
