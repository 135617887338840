<template>
  <div class="guahaoqueren">
    <van-cell-group>
      <van-cell title="医生名称" :value="yuyueInfo.doctorName || '————'" />
      <!-- <van-cell title="挂号类型" value="预约挂号" /> -->
      <van-cell title="医生职称" :value="yuyueInfo.titleName || '————'" />
      <van-cell title="就诊科室" :value="deptInfo.deptName" />
      <van-cell class="item-val" title="就诊时段" :value="`${yuyueInfo.day} ${yuyueInfo.noon === '1' ? '上午' : yuyueInfo.noon === '2' ? '下午' : '全天'}${yuyueInfo.startTime}`"/>
      <van-cell class="item-val" title="挂号金额" :value="`￥${yuyueInfo.registerMoney}`" />
    </van-cell-group>
    <selectPatient @selectChange="selectChange" />
    <van-button :loading='btnLoading' type="info" class="guahaoqueren-btn" :disabled="!choosePatient" @click="querenyuyue">确认预约</van-button>
    <van-dialog v-model="showDialog" title="提示" @confirm="dialogConfirm">
      <div class="dialog-text">
        <p>预约接种HPV系列疫苗时需携带到现场材料如下</p>
        <p>①本人身份证;</p>
        <p>②本人或配偶名下北河泾街道房产证(配偶名下的，需提供结婚证);</p>
        <p>③未成年人无房产证者，提供父母名下房产时另需出示出生证明或户口本以证明家庭关系;</p>
        <p>④没有房产证的居民请携带北河泾户籍证明或居住证(居住证发证日期超过半年者，需至社区警务室开具流动人口信息单证明);</p>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import selectPatient from '../../components/selectPatient'
export default {
  name: 'Guahaoqueren',
  components: {
    selectPatient
  },
  filters: {
    noon: (val) => {
      switch (val) {
        case '1':
          return '上午'
        case '2':
          return '下午'
        case '3':
          return '全天'
        default:
          return ''
      }
    }
  },
  data () {
    return {
      choosePatient: '',
      yuyueInfo: '',
      deptInfo: '',
      btnLoading: false,
      detailsId: '',
      showDialog: false,
      activeCode: '010911'
    }
  },
  created () {
    this.yuyueInfo = JSON.parse(localStorage.getItem('yuyueInfo'))
    this.deptInfo = JSON.parse(localStorage.getItem('deptInfo'))
  },
  mounted () {},
  methods: {
    selectChange(item) {
      this.choosePatient = item
    },
    dialogConfirm() {
      this.showDialog = false
      this.$nextTick(() => {
        this.$router.push(`/guahaoxiangqing?id=${this.detailsId}`)
      })
    },
    getSelectData() {
      const yuyueInfo = this.yuyueInfo
      const deptInfo = this.deptInfo
      const choosePatient = this.choosePatient
      const parms = {
        deptCode: yuyueInfo.deptId, // 挂号科室
        deptName: deptInfo.deptName, // 挂号科室名称
        doctorName: yuyueInfo.doctorName, // 医生姓名
        keepId: yuyueInfo.keepid || '', // 排班id 123
        patientId: choosePatient.id, // 患者id
        patientName: choosePatient.patientName, // 患者姓名
        registerType: 1, // 1 预约挂号
        titleName: yuyueInfo.doctorTitle, // 医生职称
        money: yuyueInfo.registerMoney,
        startTime: yuyueInfo.startTime && yuyueInfo.startTime.split('--')[0],
        endTime: yuyueInfo.endTime || yuyueInfo.startTime.split('--')[1],
        doctorId: yuyueInfo.doctorId || '', // 医生id
        doctorCode: yuyueInfo.doctorCode, // 医生code
        startNo: yuyueInfo.keepQuota || '', // 号源
        day: yuyueInfo.day, // 时间
        responseType: '1',
        preType: '6',
        scheduleNo: yuyueInfo.schemaId, // 排班号
        schemaType: yuyueInfo.schemaType
      }
      return parms
    },
    querenyuyue(id) {
      if (!this.choosePatient?.id) {
        this.$toast({
          message: '请选择就诊人',
          duration: 1500
        })
        return
      }
      this.btnLoading = true
      const parms = this.getSelectData()
      this.$Apis.appointment(parms).then((res) => {
        this.btnLoading = false
        if (res.code === 200) {
          this.$toast.success('预约成功')
          if (this.yuyueInfo.deptId === this.activeCode) {
            this.showDialog = true
            this.detailsId = res.data
          } else {
            this.$router.push(`/guahaoxiangqing?id=${res.data}`)
          }
        } else {
          const message = res.message?.substring(res.message.indexOf(':') + 1)
          this.$dialog.confirm({
            showCancelButton: false,
            message: message || '挂号失败'
          }).then(() => {})
        }
      }).catch(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.guahaoqueren{
  .item-val{
    /deep/.van-cell__value {
      color:#FCA237;
      padding-left: 20px;
    }
  }
  &-btn{
    display: block;
    width: 90%;
    margin: .2rem auto;
    border-radius: .2rem;
  }
  .dialog-text{
    padding: 20px;
    line-height: 1.5;
  }
}
</style>
