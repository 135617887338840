<template>
  <div class="calendar">
    <div class="calendar-week">
      <div class="calendar-week-item" v-for="(item, index) in weekList" :key="item">{{weekList[(todayWeek + index + 6) % 7]}}</div>
    </div>
    <div class="calendar-date">
      <div class="calendar-date-item"  v-for="item in dateList" :key="item.num" @click="activeDateChange(item.date)">
        <span :class="{'calendar-date-active': item.date === activeDate }">{{item.num}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Calendar',
  props: {
    selectDate: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data () {
    return {
      weekList: ['一', '二', '三', '四', '五', '六', '日'],
      dateList: [],
      showDayNum: 7, // 显示多少天
      activeDate: '', // 选中的日期
      todayWeek: 0 // 今天周几
    }
  },
  created () {
    this.todayWeek = new Date().getDay()
    this.dateList = this.setDateList()
    this.activeDate = this.selectDate || moment().format('yyyy-MM-DD')
  },
  mounted () {},
  methods: {
    activeDateChange(date) {
      if (this.activeDate === date) return
      this.activeDate = date
      this.$emit('changeDate', date)
    },
    setDateList() {
      const arr = []
      for (let i = 0; i < this.showDayNum; i++) {
        arr.push({
          num: moment().subtract(-i, 'days').format('DD'),
          date: moment().subtract(-i, 'days').format('yyyy-MM-DD')
        })
      }
      return arr
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
@item: {
  width: .3rem;
  height: .3rem;
  text-align: center;
  line-height: .3rem;
}
.calendar{
  background: #fff;
  padding: .1rem .1rem;
  // border: 1px solid #ccc;
  &-week{
    padding: .05rem .1rem;
    display:flex;
    justify-content: space-between;
    background: #eee;
    &-item{
      width: 14.28%;
      height: .3rem;
      text-align: center;
      line-height: .3rem;
    }
  }
  &-date{
    background: #f9f9f9;
    padding: 0 .1rem;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-item{
      width: 14.28%;
      height: .3rem;
      padding: .08rem 0;
      text-align: center;
      line-height: .3rem;
      border-bottom: 1px solid #f5f5f5;
    }
    &-active{
      display: inline-block;
      width: .3rem;
      height: .3rem;
      border-radius: 50%;
      background: #3f65e2;
      color: #fff;
    }
  }
}
</style>
