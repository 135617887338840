<template>
  <div>
    <div>
      <div class="date-controller">
        <div class="left" @click="goLastDay">
          <van-icon name="arrow-left" />
          <div>前一天</div>
        </div>
        <div class="middle">
          <van-icon name="notes-o" />
          <div>{{ currentDate }}</div>
        </div>
        <div class="right" @click="goNextDay">
          <div>后一天</div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="total">当日费用：￥{{ total }}</div>
      <div class="item">
        <table class="table">
          <tr class="bor">
            <th>项目名称</th>
            <th style="width:40px">数量</th>
            <th style="width:50px">单位</th>
            <th>金额</th>
            <!-- <th>时间</th> -->
          </tr>
          <tr v-for="item in list" :key="item.id">
            <td>{{item.itemName}}</td>
            <td>{{item.amount}}</td>
            <td>{{item.units}}</td>
            <td>{{item.totalCosts}}</td>
            <!-- <td>{{item.time}}</td> -->
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      list: [],
      day: 24 * 60 * 60 * 1000,
      detailList: [],
      currentDate: '',
      startTemp: 0,
      endTemp: 0,
      total: ''
    }
  },
  created() {
    this.currentDate = this.$route.query.currentDate
    this.detailList = this.$route.query.detailList
    this.init()
    this.setList()
  },
  mounted() {},
  methods: {
    init() {
      this.endTemp = this.getTimestemp(this.detailList[0].date)
      this.startTemp = this.getTimestemp(this.detailList.at(-1).date)
    },
    setList() {
      const temp = this.detailList.find(item => {
        return item.date === this.currentDate
      })
      this.list = temp.orders
      this.total = temp.totalCosts
    },
    getTimestemp(timeStr) {
      const timeArr = timeStr.split('-').map(item => Number(item))
      const timestemp = new Date(
        timeArr[0],
        timeArr[1] - 1,
        timeArr[2]
      ).getTime()
      return timestemp
    },
    getTimeStr(timestemp) {
      const year = new Date(timestemp).getFullYear()
      const month =
        new Date(timestemp).getMonth() + 1 > 9
          ? new Date(timestemp).getMonth() + 1
          : '0' + (new Date(timestemp).getMonth() + 1)
      const day =
        new Date(timestemp).getDate() > 9
          ? new Date(timestemp).getDate()
          : '0' + new Date(timestemp).getDate()
      return `${year}-${month}-${day}`
    },
    goLastDay() {
      if (this.getTimestemp(this.currentDate) - this.day < this.startTemp) {
        this.$toast('已经到第一天了！')
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.currentDate = this.getTimeStr(
          this.getTimestemp(this.currentDate) - this.day
        )
        this.setList()
      }
    },
    goNextDay() {
      if (this.getTimestemp(this.currentDate) + this.day > this.endTemp) {
        this.$toast('已经到最后一天了！')
        // eslint-disable-next-line no-useless-return
        return
      } else {
        this.currentDate = this.getTimeStr(
          this.getTimestemp(this.currentDate) + this.day
        )
        this.setList()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.date-controller {
  padding: 0.2rem 0.12rem;
  box-sizing: border-box;
  height: 52px;
  background-color: #fff;
  display: flex;
  align-items: center;
  .left {
    flex: 0 0 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .middle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right {
    flex: 0 0 80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.total {
  padding: 0.15rem;
}
.item {
  padding: 0.1rem 0.1rem 0;
  background: #fff;
  min-height: calc(100vh - 96px);
  box-sizing: border-box;
  .table {
    width: 100%;
    tr {
      border-bottom: 1px solid #ccc;
    }
    th {
      line-height: 0.3rem;
      font-size: 0.14rem;
    }
    td {
      line-height: 0.3rem;
      font-size: 0.12rem;
    }
  }
}
</style>
