<template>
  <div class="outpatient-box">
    <!-- <van-cell title="选择挂号日期" :value="selectTime" @click="timeShow = true" is-link />
    <van-calendar v-model="timeShow" :max-date="maxDate" @confirm="calendarSelectFun" /> -->
    <div class="tab-select-box">
      <!-- <div class="registered-type">
        <div :class="{active: showDate}" @click="showDate = true">按日期挂号</div>
        <div v-if="isProfessor" :class="{active: !showDate}" @click="showDate = false">按医生挂号</div>
      </div> -->
      <span>选择挂号日期</span>
      <div v-show="showDate" @click="timeShowFun">{{ selectTime }} <van-icon :name="timeShow ? 'arrow-down' : 'arrow-up'"/></div>
    </div>
    <!-- 按日期 -->
    <div v-show="showDate">
      <transition name="fade">
        <Calendar v-show="timeShow" @changeDate="changeDate"></Calendar>
      </transition>
      <!-- <NoData v-if="!keepList.length && !dataLoading"></NoData> -->
      <!-- <time-cell v-else-if="1===2" :list="keepList"></time-cell> -->
      <!-- <van-cell-group v-else>
        <van-cell v-for="item in keepList" :key="item.id" @click="doctorInfoFun(item)" is-link>
          <img class="doctor-img" slot="icon" src="~@/assets/images/doctors.png" />
          <div class="doctor-info" slot="title">
            <div class="doctor-name">{{item.doctorName}}</div>
            <div class="doctor-hint">{{item.titleName}}</div>
          </div>
          <span slot="default">余号:{{item.keepQuota}}&emsp;&emsp;￥{{item.registerMoney}}</span>
        </van-cell>
      </van-cell-group> -->
      <van-tabs v-model="activeName" class="tabs-box">
        <van-tab title="普通号" name="ordinary">
          <NoData v-if="!keepList.length && !dataLoading"></NoData>
          <!-- <time-cell v-else :list="keepList"></time-cell> -->
          <van-cell-group v-else>
            <van-cell v-for="item in keepList" :key="item.id" @click="doctorInfoFun(item)">
              <img class="doctor-img" slot="icon" src="~@/assets/images/doctors.png" />
              <div class="doctor-info" slot="title">
                <div class="doctor-name">{{item.doctorName}}</div>
                <div class="doctor-hint">{{item.titleName}}</div>
              </div>
            </van-cell>
          </van-cell-group>
        </van-tab>
        <van-tab :title="id === '010911' ? '义诊号' : '专家号'" name="experts">
          <NoData v-if="!doctorList.length && !dataLoading"></NoData>
          <van-cell-group v-else>
            <van-cell v-for="item in doctorList" :key="item.id" @click="doctorInfoFun(item)">
              <img class="doctor-img" slot="icon" src="~@/assets/images/doctors.png" />
              <div class="doctor-info" slot="title">
                <div class="doctor-name">{{item.doctorName}}</div>
                <div class="doctor-hint">{{item.titleName}}</div>
              </div>
            </van-cell>
          </van-cell-group>
        </van-tab>
      </van-tabs>
      <van-loading v-if="dataLoading" />
    </div>
    <!-- 按医生挂号 -->
    <div v-show="!showDate">
      <NoData v-if="!doctorList.length && !dataLoading"></NoData>
      <van-cell-group v-else>
        <van-cell v-for="item in doctorList" :key="item.id" @click="doctorInfoFun(item)">
          <img class="doctor-img" slot="icon" src="~@/assets/images/doctors.png" />
          <div class="doctor-info" slot="title">
            <div class="doctor-name">{{item.doctorName}}</div>
            <div class="doctor-hint">{{item.titleName}}</div>
          </div>
        </van-cell>
      </van-cell-group>
      <van-loading v-if="dataLoading" />
    </div>
  </div>
</template>

<script>
// import TimeCell from './components/timeCell'
import Calendar from '@/components/calendar'
export default {
  name: 'Registered',
  // components: { TimeCell, Calendar },
  components: { Calendar },
  data() {
    return {
      showDate: true,
      maxDate: '',
      id: '',
      activeName: 'ordinary',
      selectTime: '',
      registeredType: 'time',
      timeShow: true,
      dataLoading: false,
      doctorList: [],
      keepList: [],
      isProfessor: false // 判断预约挂号是否为专家 -> 是专家则显示按医生挂号
    }
  },
  created() {
    const id = this.$route.query.id || ''
    if (id === '030801') {
      const deptInfo = {
        deptCode: '030801',
        deptName: '成人疫苗预约'
      }
      localStorage.setItem('deptInfo', JSON.stringify(deptInfo))
    } else {
      const deptNameList = JSON.parse(localStorage.deptInfo).deptName.split('')
      if (deptNameList.at(-1) === '家' && deptNameList.at(-2) === '专') {
        this.isProfessor = true
      }
    }
    this.selectTime = this.$utils.dateFormat('YYYY-mm-dd', new Date())
    this.id = id
    this.getKeep()
    // this.getDoctorList()
  },
  methods: {
    // 日期是否展开
    timeShowFun() {
      this.timeShow = !this.timeShow
    },
    changeDate(date) {
      this.selectTime = date
      this.getKeep()
    },
    // 选择日期 格式化 赋值
    calendarSelectFun(value) {
      this.selectTime = this.$utils.dateFormat('YYYY-mm-dd', value)
      this.timeShow = false
      this.getKeep()
    },
    // 挂号方式 切换
    registeredTypeFun(type) {
      this.registeredType = type
    },
    // 挂号 预约 医生
    doctorInfoFun(item) {
      // this.$router.push(`/registeredDetails?doctorId=${item.doctorId ?? item.id}&doctorCode=${item.doctorCode}&doctorName=${item.doctorName}&titleName=${item.titleName || ''}&deptCode=${this.id}&time=${this.selectTime}`)
      /* this.$router.push({
        path: '/registeredDetails',
        query: {
          doctorId: item.doctorId ?? item.id,
          titleCode: item.titleCode,
          doctorCode: item.doctorCode,
          doctorName: item.doctorName,
          schemaType: item.schemaType,
          titleName: item.titleName || '',
          deptCode: this.id,
          time: this.selectTime
        }
      }) */
      localStorage.setItem('registeredDetails', JSON.stringify(item))
      this.$router.push(`/registeredDetails?deptCode=${this.id}&time=${this.selectTime}`)
    },
    toQueren(item) {
      localStorage.setItem('yuyueInfo', JSON.stringify(item))
      this.$router.push(`/guahaoqueren?id=${item.id}`)
    },
    // 获取普通号 排版信息
    getKeep() {
      const parms = {
        day: this.selectTime,
        deptCode: this.id,
        registerType: 1
      }
      this.dataLoading = true
      this.$Apis.getKeeps(parms).then(res => {
        this.dataLoading = false
        if (res.code === 200) {
          this.keepList = res.data.common
          this.doctorList = res.data.export
        } else {
          this.keepList = []
        }
      }).catch(() => {
        this.keepList = []
        this.dataLoading = false
      })
    },
    getDoctorList() {
      if (!this.isProfessor) return // 如非专家 则不发起医生列表请求
      this.$Apis.getDoctorList({ deptId: this.id }).then(res => {
        if (res.code === 200) {
          this.doctorList = res.data
        } else {
          this.doctorList = []
        }
      }).catch(() => {
        this.doctorList = []
      })
    }
  }
}
</script>

<style scoped lang="less">
  /deep/.van-loading{
    margin-top: 1rem;
    text-align: center;
  }
  .tab-select-box {
    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    background-color: #ffffff;
    align-items: center;
    padding: 10px;
  }

  .registered-type {
    display: flex;
    border: 1px solid #00a6ff;
    color: #00a6ff;
    border-radius: 4px;

    > div {
      padding: 8px;
    }

    .active {
      background-color: #00a6ff;
      color: #fff;
    }
  }

  .van-cell {
    align-items: center;
  }

  .doctor-img {
    width: 40px;
    height: 50px;
    margin-right: 10px;
  }

  .doctor-hint {
    color: #989898;
  }

  .doctor-num {
    font-size: 12px;
    color: #ffffff;
    > span {
      background-color: #d2d2d2;
      margin-right: 5px;
      border-radius: 4px;
      padding: 2px 5px;
    }
  }
</style>
