<template>
  <div class="baogao-detail">
    <table class="table">
      <tr class="bor">
        <th></th>
        <th>检验项目</th>
        <th>结果</th>
        <th>参考值</th>
        <th>单位</th>
      </tr>
      <tr v-for="item in list" :key="item.id">
        <td>{{item.itemCode}}</td>
        <td>{{item.inspectionItemName}}</td>
        <td>{{item.inspectionResult}}</td>
        <td>{{item.normalRange}}</td>
        <td>{{item.inspectionUnit}}</td>
      </tr>
    </table>
    <div v-if="loading" class="loading-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaoGaoDetail',
  data() {
    return {
      list: [],
      patientId: '',
      inspectionId: '',
      loading: true
    }
  },
  created() {
    this.patientId = this.$route.query.id
    this.inspectionId = this.$route.query.inspectionId
    this.getReportDetail()
  },
  mounted() {},
  methods: {
    getReportDetail() {
      this.loading = true
      const obj = {
        inspectionId: this.inspectionId
      }
      this.$Apis.getMZJYReportDetail(obj).then(res => {
        if (res.code === 200) {
          this.list = res.data
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.baogao-detail{
  padding: .1rem .1rem 0;
  background: #fff;
  height: calc(100vh - .1rem);
  .table{
    width: 100%;
    tr{
      border-bottom: 1px solid #ccc;
    }
    th{
      line-height: .3rem;
      font-size: .14rem;
    }
    td{
      line-height: .3rem;
      font-size: .12rem;
    }
  }
}
.loading-wrap{
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
