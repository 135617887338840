<template>
  <div class="my-line">
    <img class="no-patient-pic" src="~@/assets/images/no-content.png" alt="">
    <p>暂无排队记录</p>
  </div>
</template>

<script>
export default {
  name: 'MyLine',
  data() {
    return {

    }
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.my-line {
  text-align: center;
  margin-top: 1rem;
  p{
    line-height: 1rem;
  }
}
</style>
