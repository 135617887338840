<template>
  <div class="select-patient">
    <div class="title">选择就诊人</div>
    <div class="select-patient-list" v-for="(item, index) in patientList" :key="item.id" @click="selectChange(item, index)">
      <div class="select-patient-item">
        <div class="item-left">
          <img src="@/assets/images/tx.png" alt="" width="40" height="40" />
        </div>
        <div class="item-right">
          <van-icon class="item-right-icon" :class="activeNum === index ? 'item-right-icon-active': ''" name="passed" />
          <div class="item-right-name">{{item.patientName}}<span v-if="item.defaultStatus*1 === 1">默认</span></div>
          <p class="item-right-num">
            {{ item.hisPatientId}}
          </p>
        </div>
      </div>
    </div>
    <van-cell-group @click="toAddPatient">
      <van-cell title="添加就诊人" icon="add-o" :value="`还可以添加${annotationNum}人`" is-link />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  name: 'SelectPatient',
  data() {
    return {
      id: '',
      patientList: [],
      annotationNum: 4,
      activeNum: '',
      isNotSelf: 1
    }
  },
  created() {
    const userInfo = this.$store.state.userInfo
    this.appletsId = this.$store.state.openId
    if (userInfo) {
      this.id = userInfo.id
      this.getPatientList()
    }
  },
  mounted() {},
  methods: {
    getPatientList() {
      if (!this.id) return
      const obj = {
        id: this.id,
        isNotRegister: 1
      }
      this.$Apis.getPatientListVo(obj).then(res => {
        if (res.code === 200) {
          this.patientList = res.data.patientList
          this.annotation = res.data.annotation
          this.annotationNum = res.data.annotationNum
          this.activeNum = 0
          this.isNotSelf = res.data.isNotSelf
          const dataActive = res.data.patientList[0]
          this.$emit('selectChange', dataActive)
          this.$store.commit('updateInfo', {
            name: 'patientList',
            value: res.data.patientList
          })
        }
      })
    },
    selectChange(item, index) {
      this.activeNum = index
      this.$emit('selectChange', item)
    },
    toAddPatient() {
      if (this.annotationNum === '' || this.annotationNum === 0) return
      const isNotSelf = this.isNotSelf * 1
      const id = isNotSelf ? this.appletsId : this.id
      this.$router.push(`/addPatient?id=${id}&isNotSelf=${isNotSelf}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.select-patient {
  .title {
    font-size: 0.16rem;
    line-height: 1.5;
    margin: 0.1rem 0.15rem;
    color: #666;
  }
  &-list {
    padding: 0.1rem 0.15rem;
    background: #fff;
    border-bottom: 1px solid #eee;
  }
  &-item {
    display: flex;
    .item-left {
      margin: 0 0.1rem 0 0;
    }
    .item-right {
      flex-grow: 1;
      &-name {
        font-size: 0.16rem;
        line-height: 0.3rem;
        color: #333;
        span {
          display: inline-block;
          margin-left: 0.05rem;
          font-size: 0.14rem;
          line-height: 0.1rem;
          padding: 0.04rem;
          color: #eb9797;
          border: 1px solid #eb9797;
        }
      }
      &-num {
        font-size: 0.14rem;
        color: #999;
        &-red {
          color: #eb9797;
        }
      }
      &-icon {
        float: right;
        margin-top: .1rem;
        font-size: .26rem;
        color: #999;
        font-weight: 600;
      }
      &-icon-active{
        color: #1677ff;
      }
    }
  }
}
</style>
