<template>
  <div class="yuyueguahao">
    <!-- <van-search
      v-model="search"
      :show-action="showAction"
      placeholder="请输入搜索门诊"
      @search="searchDept"
      @cancel="onCancel"
      @focus="onFocus"
    /> -->
    <van-list class="list">
      <van-cell v-for="item in list" :key="item.id" :title="item.deptName" is-link @click="selectDept(item)"/>
    </van-list>
    <!-- 默认医生列表 -->
    <!-- <van-tree-select v-show="!showAction" :items="deptTree" :main-active-index.sync="active" height="100%">
      <template #content>
          <van-cell v-for="item in deptTreeActiveList" :key="item.id" :title="item.deptName" is-link @click="selectDept(item)"/>
      </template>
    </van-tree-select> -->
    <!-- 搜索接口显示 -->
    <!-- <van-list v-show="showAction" v-model="loading" :finished="finished" class="list">
      <van-cell v-for="item in list" :key="item.id" :title="item.deptName" is-link @click="selectDept(item)"/>
    </van-list> -->
    <van-dialog v-model="showDialog" title="提示" show-cancel-button @confirm="dialogConfirm">
      <div class="dialog-text">
        <p>接种时需符合的以下条件(1-4项符合其中一项即可)：</p>
        <p>(1) 具有北河泾街道户籍(户口本、接种本人身份证);</p>
        <p>(2) 本人或配偶北河泾街道房产证(配偶名下的需提供结婚证);</p>
        <p>(3) 未成年人无房产证者，提供父母名下房产时需要出示户口本或出生医学证明以证明家庭关系;</p>
        <p>(4) 在北河泾街道居住的非户籍居民(由辖区警务室提供居住满6个月的流动人口信息登记证明);</p>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import mixin from '../../mixins'
export default {
  name: 'YuYueGuaHao',
  mixins: [mixin],
  data() {
    return {
      showDialog: false,
      showAction: false,
      active: 0,
      loading: false,
      finished: false,
      patientId: '',
      search: '',
      deptTree: [],
      list: [],
      activeCode: '010911'
    }
  },
  computed: {
    deptTreeActiveList: function() {
      let list = []
      if (this.deptTree[this.active]?.childNodes) {
        list = this.deptTree[this.active]?.childNodes
      }
      return list
    }
  },
  created() {
    this.patientId = this.$store.state.userInfo?.id ?? ''
    this.getDeptList()
  },
  mounted() {
  },
  methods: {
    onCancel() {
      this.showAction = false
    },
    onFocus() {
      this.showAction = true
    },
    /**
     * 搜索门诊
     */
    searchDept() {
      this.loading = true
      this.$Apis.deptList({ deptName: this.search }).then(res => {
        this.loading = false
        this.finished = true
        if (res.code === 200) {
          this.list = res.data.map(item => {
            item.text = item.deptName
            return item
          })
        }
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    getDeptList() {
      this.$Apis.deptTree().then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    collapseChange() {},
    itemChange() {},
    dialogConfirm() {
      this.showDialog = false
      this.$nextTick(() => {
        this.$router.push(`/registered?id=${this.activeCode}`)
      })
    },
    /**
     * 选择科室
     */
    selectDept(item) {
      localStorage.setItem('deptInfo', JSON.stringify(item))
      if (item.deptCode === this.activeCode) {
        this.showDialog = true
      } else {
        this.$router.push(`/registered?id=${item.deptCode}`)
      }
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.yuyueguahao{
  background-color:#fff;
  height: calc(100vh - .6rem);
  /deep/.van-sidebar-item--select::before{
    background-color: #2c3ff0;
  }
  .dialog-text{
    padding: 20px;
    line-height: 1.5;
  }
}
</style>
