import { render, staticRenderFns } from "./baogaoList.vue?vue&type=template&id=e4f275ae&scoped=true&"
import script from "./baogaoList.vue?vue&type=script&lang=js&"
export * from "./baogaoList.vue?vue&type=script&lang=js&"
import style0 from "./baogaoList.vue?vue&type=style&index=0&id=e4f275ae&rel=stylesheet%2Fless&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4f275ae",
  null
  
)

export default component.exports