<template>
  <div class="dynamic-info">
    <!-- <h2 class="dynamic-title">五院药师乒乓球队在第三届苏州医院药师乒乓赛中获得佳绩</h2>
    <p class="dynamic-name">作者:小王 <span class="dynamic-time">2020年08月31日</span></p>
    <p class="dynamic-main">11月30号下午，苏州市药学会举办了第三届“南京正大天晴杯，医院药师乒乓球比赛。全市共有苏大附一院、苏大附二院、市立医院、张家港市第一人民医院等12支代表队参加了比赛。</p>
    <p class="dynamic-main">苏州市第五人民医院程军平院长带领医院药师乒乓队-杨中、许俊翱、俞立出征参赛。比赛现场高手如云，五院选手们精神饱满、全身投入奋力挥拍，好球连连，精彩不断。只见乒乓球在球桌上快速地旋转飞梭，直拍、横拍、扣球、旋转球、弧线球....让人眼花缭乱，观众席中不时传来热烈的喝彩声，兴奋的“好球声”不断。最终，苏州五院药师乒乓队获得男子双打第一名、男子单人第二名、第四名的佳绩。
      </p>
    <p class="dynamic-main">苏州五院药师乒乓队已连续三年参加苏州药学会医院药师乒乓球比赛。通过与其他医院球员的球技切磋，既提升了球技、展现了五院人的拼搏精神，又加强了医院间、药师间的联系，“以小乒乓推动大药剂”实现球技、业务双提升。同时，“ 敢拼搏、不服输”的运动竞技精神也激励着我们更好的投入工作，共创医院美好未来。</p> -->
    <template v-if="id === 1">
      <h3 class="dynamic-title">
        依托高水平医疗机构，加速我院药物临床试验机构成长
      </h3>
      <p class="dynamic-name">
        来源：本站&nbsp;&nbsp;&nbsp;&nbsp;浏览：102次&nbsp;&nbsp;&nbsp;2021-07-1
      </p>
      <div class="gj-divider"></div>
      <div class="article-body">
        <p>
          <img
            src="http://www.szgjyy.com/upload/202107/06/202107061455386843.png"
            width="100%"
            alt=""
          />
        </p>
        <p>
          <img
            src="http://www.szgjyy.com/upload/202107/06/202107061455458400.png"
            width="100%"
            alt=""
          />
        </p>
        <p>
          <img
            src="http://www.szgjyy.com/upload/202107/06/202107061455521049.png"
            width="100%"
            alt=""
          />
        </p>
        <p>
          <img
            src="http://www.szgjyy.com/upload/202107/06/202107061455590457.png"
            width="100%"
            alt=""
          />
        </p>
      </div>
    </template>
    <template v-if="id === 2">
      <h3 class="dynamic-title">
        党史学习教育 | “沉浸式”体验，让党史学习入脑入心
      </h3>
      <p class="dynamic-name">
        来源：本站&nbsp;&nbsp;&nbsp;&nbsp;浏览：486次&nbsp;&nbsp;&nbsp;2021-05-28
      </p>
      <div class="gj-divider"></div>
      <div class="article-body">
        <p style="text-align:center;">
          <img
            src="http://www.szgjyy.com/upload/202106/10/202106101447492600.png"
            width="100%"
            alt=""
          />
        </p>
        <p style="text-align:center;">
          <img
            src="http://www.szgjyy.com/upload/202106/10/202106101447549508.png"
            width="100%"
            alt=""
          />
        </p>
      </div>
    </template>
    <template v-if="id === 3">
      <h3 class="dynamic-title">
        健康惠民-党员先行｜看！这些医务工作者在干什么？
      </h3>
      <p class="dynamic-name">
        来源：本站&nbsp;&nbsp;&nbsp;&nbsp;浏览：471次&nbsp;&nbsp;&nbsp;2021-05-28
      </p>
      <div class="gj-divider"></div>
      <div class="article-body">
        <p style="text-align:center;">
          <img
            src="http://www.szgjyy.com/upload/202106/10/202106101446566805.png"
            width="100%"
            alt=""
          />
        </p>
        <p style="text-align:center;">
          <img
            src="http://www.szgjyy.com/upload/202106/10/202106101447039596.png"
            width="100%"
            alt=""
          />
        </p>
        <p style="text-align:center;">
          <img
            src="http://www.szgjyy.com/upload/202106/10/202106101447102695.png"
            width="100%"
            alt=""
          />
        </p>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      id: 1
    }
  },
  created() {
    this.id = this.$route.query.id * 1
  },
  mounted() {},
  methods: {}
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.dynamic-info {
  background-color: #fff;
  padding-bottom: 0.2rem;
}
.dynamic-title {
  padding: 0.2rem;
  margin-bottom: 0.1rem;
  font-size: 0.16rem;
  font-weight: bold;
  line-height: 2;
  text-align: center;
  border-bottom: 1px solid #eee;
}
.dynamic-name {
  color: #666;
  text-align: right;
  margin: 0.2rem 0.3rem;
}
.dynamic-time {
  margin-left: 0.1rem;
}
.dynamic-main {
  font-size: 0.14rem;
  line-height: 2;
  color: #333;
  text-indent: 2em;
  margin: 0.06rem 0.2rem 0.1rem;
  text-align: justify;
}
</style>
