<template>
  <div>
    <div v-for="(item, index) in list" :key="index" class="gj-list">
      <van-checkbox v-if="item.status * 1 === 0" v-model="item.checked" @click="checkChange(item)">
        <mzjfListItem :item="item" />
      </van-checkbox>
      <mzjfListItem v-else :item="item" />
      <van-button class="fr" type="info" size="mini" @click="toDetail(item.clinicNo)">处方明细</van-button>
      <van-button v-if="item.status*1 === 1" class="fr mr20" type="info" size="mini" @click="toVoucher(item.id)">电子凭证</van-button>
    </div>
  </div>
</template>
<script>
import mzjfListItem from './list-item'
export default {
  name: '',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    status: val => {
      switch (val) {
        case 0:
          return '待缴费'
        case 1:
          return '缴费成功'
        case 2:
          return '已作废'
        default:
          return ''
      }
    }
  },
  components: {
    mzjfListItem
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {
    checkChange(id) {
      this.$emit('checkChange', id)
    },
    toVoucher(id) {
      this.$Apis.getEinvoiceDetailsById({ Id: id }).then(res => {
        if (res.code === 200) {
          window.location.href = res.data.dzpjurl
        } else {
          this.$toast(res.message)
        }
      })
    },
    toDetail(id) {
      this.$router.push(`/jiaofeimingxi?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped></style>
