<template>
  <div>
    <div class="anomaly-box">
      <div class="anomaly-top">
        <img src="@/assets/images/report/anomaly_bg.png" alt="" />
      </div>
      <ul class="anomaly-title">
        <li>
          姓名：<span>{{ detailsAbnormal.name }}</span>
        </li>
        <li>
          性别：<span>{{ detailsAbnormal.sex }}</span>
        </li>
        <li>
          年龄：<span>{{ detailsAbnormal.age }}</span>
        </li>
        <li>体检编号：{{ detailsAbnormal.cardNo }}</li>
        <li>体检日期：{{ detailsAbnormal.operDate }}</li>
      </ul>
      <div class="anomaly-list-box">
        <ul class="anomaly-list">
          <li v-for="(item, index) in detailsAbnormal.itemList" :key="index">
            <div class="anomaly-list-top">
              <span>{{ item.chineseName }}</span
              ><span>{{ item.testItemUnit }}</span>
            </div>
            <div class="anomaly-list-bottom">
              <div>
                <p>{{ item.testItemReference }}</p>
                <p>正常参考值范围</p>
              </div>
              <div>{{ item.quantitativeResult }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detailsAbnormal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.anomaly-box {
  position: relative;
  width: 100%;
  .anomaly-top {
    margin-top: 16px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .anomaly-title {
    position: absolute;
    left: 20px;
    top: 24px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    li {
      line-height: 28px;
    }
  }
  .anomaly-list-box {
    width: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .anomaly-list {
    position: absolute;
    top: 180px;
    font-size: 14px;
    background: #fff;
    font-weight: bold;
    border-radius: 5px;
    width: 330px;
    padding: 10px;
    li {
      margin-bottom: 12px;
      .anomaly-list-top {
        background: #e6f2fe;
        border: 1px dashed #969798;
        padding: 12px 15px;
      }
      .anomaly-list-bottom {
        background: #ecf5fe;
        border: 1px dashed #969798;
        border-top: 0px;
        padding: 12px 15px;
        display: flex;
        justify-content: space-between;
        div:nth-of-type(1) {
          p:nth-of-type(1) {
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: bold;
            color: #333;
          }
          p:nth-of-type(2) {
            margin-bottom: 10px;
            font-size: 12px;
            color: #777;
          }
        }
        div:nth-of-type(2) {
          color: #fb5555;
          font-size: 20px;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
