<template>
  <div class="my-patient-page">
    <van-loading v-if="loading" type="spinner" class="top-loading" />
    <template v-else>
      <ul class="lists" v-if="list.length > 0">
        <li class="list-item" v-for="item in list" :key="item.id" @click="toInfo(item)">
          <div class="item-left">
            <img src="@/assets/images/tx.png" alt="" width="40" height="40" />
          </div>
          <div class="item-right">
            <div class="item-right-info">
              <h4 class="name">{{item.patientName}}<span v-if="item.defaultStatus*1 === 1">默认</span></h4>
              <p class="num">{{item.hisPatientId}}</p>
            </div>
            <van-icon class="fr" name="arrow" color="#ccc" />
          </div>
        </li>
        <li class="list-item">
          <div class="item-left-bottom">
            <van-icon name="plus" color="#fff" />
          </div>
          <div class="item-right" @click="toAdd">
            <div class="item-right-info">
              <h4 class="name">添加就诊人</h4>
              <p class="num">还可以添加<span class="num-red">{{annotationNum || '-'}}</span>人</p>
            </div>
            <van-icon class="fr" name="arrow" color="#ccc" />
          </div>
        </li>
      </ul>
      <div v-else class="no-patient">
        <img class="no-patient-pic" src="~@/assets/images/no-content.png" alt="">
        <p class="no-patient-text">您还没有添加就诊人</p>
        <van-button type="info" class="no-patient-btn" @click="toAdd">添加就诊人</van-button>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: 'MyPatient',
  components: {},
  data() {
    return {
      id: '',
      url: '',
      appletsId: '',
      annotationNum: '',
      annotation: '',
      isNotSelf: 1,
      list: [],
      loading: false
    }
  },
  created() {
    const url = this.$route.query.url
    const appletsId = this.$store.state.openId
    this.url = url
    this.appletsId = appletsId
    const userInfo = this.$store.state.userInfo
    if (userInfo === null || userInfo === 'null') {
      if (appletsId === null || appletsId === 'null') {
        this.$router.push('/login')
        return
      }
      this.getByAppletsId()
    } else {
      const { id } = userInfo
      this.id = id
      this.getPatientList()
    }
  },
  mounted() {},
  methods: {
    getPatientList() {
      this.loading = true
      const obj = {
        id: this.id,
        isNotRegister: 1
      }
      this.$Apis.getPatientListVo(obj).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.list = res.data.patientList
          this.annotation = res.data.annotation
          this.annotationNum = res.data.annotationNum
          this.isNotSelf = res.data.isNotSelf
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getByAppletsId() {
      this.$Apis.getByAppletsId({ appletsId: this.appletsId }).then(res => {
        if (res.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$store.commit('updateInfo', {
            name: 'userInfo',
            value: res.data
          })
          if (res.data?.id) {
            this.id = res.data.id
            this.getPatientList()
          }
        } else {
          if (res.message) {
            this.$toast.error(res.message)
          }
        }
      }).catch(err => {
        this.$toast(err)
      })
    },
    toInfo({ id, blhNo }) {
      this.$router.push(`/${this.url ? this.url : 'patientInfo'}?id=${id}&blhNo=${blhNo}`)
    },
    toAdd() {
      // if (this.annotationNum === '' || this.annotationNum === 0) return
      if (this.annotationNum === 0) return
      const isNotSelf = this.isNotSelf * 1
      const id = isNotSelf ? this.appletsId : this.id
      this.$router.push(`/addPatient?id=${id}&isNotSelf=${isNotSelf}`)
    }
  } // 方法集合
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
@height: calc(100vh - .46rem);
.my-patient-page {
  .top-loading{
    text-align: center;
    height: 1rem;
    line-height: 1rem;
  }
  min-height: @height;
  background: #ecf3f3;
  overflow: hidden;
  .lists {
    padding: 0.15rem 0.15rem 0.5rem;
    .list-item {
      padding: 0.12rem;
      background: #fff;
      height: 0.4rem;
      margin-bottom: 0.15rem;
      display: flex;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
      .item-left {
        font-size: 0;
      }
      .item-right {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 10px;
        &-info {
          .name {
            font-size: 0.16rem;
            line-height: 0.3rem;
            color: #333;
            span {
              display: inline-block;
              margin-left: 0.05rem;
              font-size: 0.14rem;
              line-height: 0.1rem;
              padding: 0.04rem;
              color: #eb9797;
              border: 1px solid #eb9797;
            }
          }
          .num {
            font-size: 0.14rem;
            color: #999;
            &-red {
              color: #eb9797;
            }
          }
        }
      }
    }
    .item-left-bottom {
      width: 0.4rem;
      height: 0.4rem;
      line-height: 0.4rem;
      text-align: center;
      background: #4ebd92;
      font-weight: bold;
      font-size: 0.2rem;
      border-radius: 0.04rem;
    }
  }
  .no-patient{
    height: @height;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &-pic{
      margin: 0 auto;
      display: block;
      width: 1.5rem;
      margin-bottom: .1rem;
    }
    &-text{
      font-size: .16rem;
      line-height: 2;
      margin-bottom: .3rem;
    }
    &-btn{
      margin: 0 auto;
      width: 80%;
      border-radius: .1rem;
    }
  }
}
</style>
