<template>
  <div class="page-container">
    <div class="content-banner">
      <img src="~@/assets/images/about-banner-rh.png" style="height:180px;width:100%">
    </div>
    <div style="padding:0px 24rpx">
      <div class="info-item">
        <div>
          {{ hospitalInfo.hospitalName }}
          <span style="color:#999;font-size:26rpx;padding-left:10px;">{{
            hospitalInfo.hospitalGrade
          }}</span>
        </div>
      </div>
      <div class="info-item">
        <div>
          <img src="~@/assets/images/tel.png" style="width:.16rem;height:.16rem;margin-right:.08rem">
          {{ hospitalInfo.hospitalPhone }}
        </div>
        <a :href="`tel:${hospitalInfo.hospitalPhone}`">拨打电话</a>
      </div>
      <div class="info-item">
        <div style="width:80%;display:flex;align-items:center;">
          <img src="~@/assets/images/address.png" style="width:.16rem;height:.16rem;margin-right:.08rem">
          <div style="display:inline-block;width:90%">{{
            hospitalInfo.hospitalAddress
          }}</div>
        </div>
        <span @click="openLocation">查看导航</span>
      </div>
      <div class="info-span">
        {{ hospitalInfo.hospitalIntroduce }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      hospitalInfo: {}
    }
  },
  created() {
    this.$Apis.hospital().then(res => {
      if (res.code === 200) {
        this.hospitalInfo = res.data
      } else {
        if (res.message) {
          this.$toast(res.message)
        }
      }
    }).catch(() => {
      this.$toast('服务器错误')
    })
  },
  mounted() {},
  methods: {
    openLocation() {
      this.$router.push('/tMap')
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.page-container{
  height: 100vh;
  background: #fff;
  overflow: auto;
}
.info-item{
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding:.1rem .1rem;
  margin-top: .05rem;
  color:#999999;
  align-items: center;
  a {
    color: #1677ff;
  }
}
.info-span{
  color:#999999;
  text-indent: 2em;
  padding: .2rem .1rem;
  line-height: 1.5;
}
.info-item span{
  color:#1677ff;
}</style>
