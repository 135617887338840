<template>
  <div class="main-container">
    <van-form @submit="onSubmit">
      <van-field
        v-model="patientName"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="inHosNum"
        name="住院号"
        label="住院号"
        placeholder="住院号"
        :rules="[{ required: true, message: '请填写住院号' }]"
      />
      <van-field
        v-model="idCard"
        name="身份证号"
        label="身份证号"
        placeholder="身份证号"
        :rules="[{ required: true, message: '请填写身份证号' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      patientName: '',
      inHosNum: '',
      idCard: ''
    }
  },
  created() {
    sessionStorage.clear()
  },
  mounted() {},
  methods: {
    onSubmit() {
      sessionStorage.setItem('zhuyuanSearchForm', JSON.stringify({
        patientName: this.patientName,
        inHosNum: this.inHosNum,
        idCard: this.idCard
      }))
      this.$router.push({
        path: '/zhuyuan/list/dayslist'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .main-container{
    // padding: 0.2rem;
  }
</style>
