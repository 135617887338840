<template>
  <div class="patient-info">
    <van-cell-group>
      <van-cell title="姓名" :value="data.patientName" />
      <van-cell title="证件类型" :value="data.documentName" />
      <van-cell title="证件号" :value="data.patientSfzh" />
      <van-cell title="手机号" :value="data.patientPhone" />
    </van-cell-group>
    <van-button class="patient-info-btn" :disabled="data.defaultStatus === 1" @click="setdefault" :loading="setLoading">设置默认就诊人</van-button>
    <van-button class="patient-info-btn" :disabled="data.defaultStatus === 1" @click="unbind" :loading="unLoading">解除绑定</van-button>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {
      data: {},
      id: '',
      patientId: '',
      setLoading: false,
      unLoading: false
    }
  },
  created () {
    const userInfo = this.$store.state.userInfo
    const { id } = userInfo
    const patientId = this.$route.query.id
    this.id = id
    this.patientId = patientId
    this.getPatientInfo()
  },
  mounted () {},
  methods: {
    getPatientInfo() {
      const { patientId } = this
      this.$Apis.getPatientInfo({ id: patientId }).then(res => {
        if (res.code === 200) {
          this.data = res.data
        }
      })
    },
    setdefault() {
      const parms = {
        id: this.id,
        patientId: this.patientId
      }
      this.$dialog.confirm({
        title: '系统消息',
        message: '是否确定设置默认就诊人？',
        confirmButtonColor: 'green'
      }).then(() => {
        this.setLoading = true
        this.$Apis.updatePatient(parms).then(res => {
          this.setLoading = false
          if (res.code === 200) {
            this.$notify({ type: 'success', message: '设置成功', duration: 500 })
            this.getPatientInfo()
          } else {
            this.$notify({ type: 'error', message: res.message })
          }
        }).catch(() => {
          this.setLoading = false
        })
      }).catch(() => {
        // on cancel
      })
    },
    unbind() {
      const parms = {
        id: this.id,
        patientId: this.patientId
      }
      this.$dialog.confirm({
        title: '系统消息',
        message: '是否确定解除绑定？',
        confirmButtonColor: 'green'
      }).then(() => {
        this.unLoading = true
        this.$Apis.delPatient(parms).then(res => {
          this.unLoading = false
          if (res.code === 200) {
            this.$notify({ type: 'success', message: '解绑成功', duration: 500 })
            this.$router.go(-1)
          } else {
            this.$notify({ type: 'error', message: res.message })
          }
        }).catch(() => {
          this.unLoading = false
        })
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.patient-info{
  &-btn{
    display: block;
    width: 80%;
    margin: .2rem auto 0;
  }
}
</style>
