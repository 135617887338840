<template>
  <div class="registeredDetails-box">
    <div class="doctor-hint">
      <div class="doctor-img">
        <img src="~@/assets/images/doctors.png">
      </div>
      <div>
        <div class="name">{{list[0] && list[0].doctorName || doctorName ||  '——'}}</div>
        <div class="title">职称：{{(list[0] && list[0].doctorTitle) || titleName || '——'}}</div>
      </div>
    </div>
    <van-tabs v-model="activeName">
      <van-tab title="挂号" name="gauHao">
        <template>
          <transition name="fade">
            <Calendar :select-date="date" @changeDate="changeDate"></Calendar>
          </transition>
          <!-- <van-cell :title="date" value="更多日期 >" @click="show = true" />
          <van-calendar v-model="show" @confirm="onConfirm" /> -->
          <div v-if="!list.length && !loading" class="no-doctor-info">当前日期，{{ doctorName }} 医生暂无排班信息</div>
          <time-cell v-else :list="list"></time-cell>
        </template>
      </van-tab>
      <van-tab title="介绍" name="jieShao">
        <template>
          <div class="jie-shao">
            <div class="jie-shao-title">介绍</div>
            <div class="jie-shao-content">暂无</div>
          </div>
        </template>
      </van-tab>
    </van-tabs>
    <van-loading v-if="loading"></van-loading>
  </div>
</template>

<script>

import Calendar from '@/components/calendar'
import TimeCell from './components/timeCell'
export default {
  name: 'registeredDetails',
  components: { Calendar, TimeCell },
  data() {
    return {
      list: [],
      doctorCode: '',
      titleCode: '',
      doctorId: '',
      deptId: '',
      date: '',
      show: false,
      loading: false,
      activeName: 'guaHao',
      doctorName: '',
      titleName: ''
    }
  },
  created() {
    const registeredDetails = JSON.parse(localStorage.getItem('registeredDetails'))
    const { doctorId, doctorCode, doctorName, titleName, titleCode } = registeredDetails
    const { deptCode, time } = this.$route.query
    // const { doctorId, doctorCode, doctorName, titleName, titleCode, deptCode, time } = this.$route.query
    this.doctorId = doctorId
    this.doctorName = doctorName
    this.titleName = titleName
    this.doctorCode = doctorCode
    this.deptCode = deptCode
    this.titleCode = titleCode
    this.date = time
    this.getDoctorKeepList()
  },
  mounted() {

  },
  methods: {
    /**
     * 日期格式化
     */
    formatDate(date) {
      return this.$utils.dateFormat('YYYY-mm-dd', date)
    },

    /**
     * 日期确认
     * @param date
     */
    onConfirm(date) {
      this.show = false
      if (this.data === this.formatDate(date)) return
      this.date = this.formatDate(date)
      this.getDoctorKeepList()
    },
    getDoctorKeepList() {
      const parms = {
        day: this.date,
        titleCode: this.titleCode,
        deptCode: this.deptCode,
        doctorCode: this.doctorCode,
        registerType: 1
      }
      this.loading = true
      this.list = []
      this.$Apis.getKeep(parms).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.list = res.data
          this.list.forEach(item => {
            item.titleName = this.titleName
            item.deptId = this.deptCode
            item.doctorId = this.doctorId
          })
        } else {
          this.list = []
        }
      }).catch(() => {
        this.loading = false
        this.list = []
      })
    },
    changeDate(date) {
      this.date = date
      this.getDoctorKeepList()
    }
  }
}
</script>

<style scoped lang="less">
  /deep/.van-loading{
    margin-top: 1rem;
    text-align: center;
  }
  .doctor-hint {
    display: flex;
    background-color: #fff;
    padding: 25px;
    align-items: center;
    .name {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .title {
      color: #9D9D9D;
    }
  }

  .jie-shao {
    background-color: #fff;
    padding: 25px;
    .jie-shao-title {
      font-size: 16px;
      margin-bottom: 5px;
    }

    .jie-shao-content {
      color: #9D9D9D;
    }
  }

  .doctor-img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    > img {
      width: 50px;
      height: 50px;
    }
  }
  .no-doctor-info{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    padding: 0 0.16rem 0.1rem 0.16rem;
    overflow: hidden;
    color: #323233;
    font-size: 0.14rem;
    line-height: 0.24rem;
  }
</style>
<style lang="less">
  .van-tabs__content {
    margin-top: 10px;
  }
</style>
