<template>
  <div class="menzhenjiaofei">
    <van-tabs v-model="activeName">
      <van-tab title="待缴费" name="1">
        <van-list
          v-model="waitLoading"
          :finished="waitFinished"
          finished-text="已经到底了"
          :immediate-check="false"
          @load="waitOnLoad"
        >
          <mzjfList :list="waitList" @checkChange="checkChange" />
        </van-list>
      </van-tab>
      <van-tab title="全部" name="2">
        <van-list
          v-model="allLoading"
          :finished="allFinished"
          finished-text="已经到底了"
          @load="onLoadAll"
        >
          <mzjfList :list="allList" @checkChange="checkChange" />
        </van-list>
      </van-tab>
    </van-tabs>
    <div class="menzhenjiaofei-btn">
      <van-button :loading="payLoading" class="bottom-btn" type="info" @click="pay">立即支付</van-button>
    </div>
  </div>
</template>

<script>
// import wx from 'weixin-jsapi'
import mzjfList from './list'
export default {
  name: 'MenZhenJiaoFei',
  components: {
    mzjfList
  },
  data() {
    return {
      userId: '',
      activeData: [],
      item: {},
      activeName: '1',
      waitList: [],
      allList: [],
      allCurrent: 1,
      waitCurrent: 1,
      pageSize: 10,
      waitLoading: false,
      allLoading: false,
      waitFinished: false,
      allFinished: false,
      payLoading: false,
      onLoad: ''
    }
  },
  created() {
    this.userId = this.$route.query.id || ''
    this.getChargeListPage(1)
    setTimeout(() => {
      this.getChargeListPage(2)
    }, 2000)
  },
  mounted() {},
  methods: {
    getChargeListPage(type) {
      if (type === 1) {
        this.waitLoading = true
      } else {
        this.allLoading = true
      }
      const obj = {
        current: type === 1 ? this.waitCurrent : this.allCurrent,
        size: this.pageSize,
        patientIds: this.userId,
        status: type === 1 ? 0 : ''
      }
      this.$Apis.chargeListPage(obj).then(res => {
        if (type === 1) {
          this.waitLoading = false
        } else {
          this.allLoading = false
        }
        if (res.code === 200) {
          if (type === 1) {
            this.waitList = [...this.waitList, ...res.data.list]
            this.waitFinished = (res.data.totalPage === 0 || res.data.totalPage === this.waitCurrent)
          } else {
            this.allList = [...this.allList, ...res.data.list]
            this.allFinished = (res.data.totalPage === 0 || res.data.totalPage >= this.allCurrent)
          }
        } else {
          if (type === 1) {
            this.waitFinished = true
          } else {
            this.allFinished = true
          }
        }
      }).catch(() => {
        if (type === 1) {
          this.waitFinished = true
          this.waitLoading = false
        } else {
          this.allFinished = true
          this.allLoading = false
        }
      })
    },
    waitOnLoad() {
      this.waitCurrent++
      this.getChargeListPage(1)
    },
    onLoadAll() {
      this.allCurrent++
      this.getChargeListPage(2)
    },
    checkChange(item) {
      if (item.checked) {
        this.activeData.push(item.id)
      } else {
        const index = this.activeData.findIndex(t => t === item.id)
        this.activeData.splice(index, 1)
      }
    },
    pay() {
      if (this.activeData.length === 0) return
      const openid = this.$store.state.openId
      const data = {
        ids: this.activeData.join(','),
        buyId: openid,
        patientId: this.userId
      }
      this.payLoading = true
      this.$Apis.wechatPay(data).then(res => {
        this.payLoading = false
        if (res.code === 200) {
          // location.href = res.data
          this.wxPay(res.data.body)
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        this.payLoading = false
        this.$toast(err.message)
      })
    },
    wxPay(data) {
      const { appId, nonceStr, paySign, signType, timeStamp } = data
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
      function onBridgeReady() {
        WeixinJSBridge.invoke( // eslint-disable-line no-undef
          'getBrandWCPayRequest', {
            appId, // 公众号名称，由商户传入
            timeStamp, // 时间戳，自1970年以来的秒数
            nonceStr, // 随机串
            package: `${data.package}`,
            signType, // 微信签名方式：
            paySign // 微信签名
          },
          function(res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') { // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              this.getChargeListPage(1)
            }
          }
        )
      }
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
@import '~@/assets/css/common';
.menzhenjiaofei{
  padding-bottom: .6rem;
  &-btn{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: .1rem 0 .2rem;
    background: @background;
    .bottom-btn{
      border-radius: .25rem;
      margin: 0 auto;
      display: block;
      width: 90%;
    }
  }
}
</style>
