<template>
  <div class="yujianfenzhen-list">
    <div v-if="list.length > 0" class="list">
      <div class="list-item" :class="item.state ? 'red' : 'green'" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
        <div class="item">
          <span class="item-name">填报时间：</span>
          <span class="item-conter">{{item.createTime}}</span>
        </div>
        <div class="item">
          <span class="item-name">患者名称：</span>
          <span class="item-conter">{{item.name}}</span>
        </div>
        <div class="item">
          <span class="item-name">填报状态：</span>
          <span class="item-conter">{{item.state ? '异常' : '正常'}}</span>
        </div>
      </div>
    </div>
    <noData v-else title="暂无预诊分诊记录"></noData>
  </div>
</template>
<script>
export default {
  name: 'YujianfenzhenList',
  data () {
    return {
      id: '',
      list: []
    }
  },
  created () {
    const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo'))
    this.id = userInfo.id
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      const obj = {
        patientId: this.id
      }
      this.$Apis.precheckGetList(obj).then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    toDetail(id) {
      this.$router.push(`/yujianfenzhen?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.yujianfenzhen-list{
  .list {
    border-radius: .05rem;
    margin: .1rem;
  }

  .list-item {
    border-radius: .1rem;
    padding: .1rem;
    margin-bottom: .1rem;
    background: url(~@/assets/images/right-arrow-white.png) no-repeat right .1rem center / .07rem .14rem;
  }

  .item {
    color: #fff;
    font-size: .14rem;
    line-height: .3rem;
  }

  .item-name {
    margin-right: .05rem;
  }

  .red{
    background-color: #fe7e7e;
  }
  .green{
    background-color: #36c5a7;
  }
}
</style>
