<template>
  <!-- <div> -->
    <!-- <div v-if="loading" class="common-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div> -->
    <!-- <div v-else-if="!transportFail" class="baogao-jc-detail"> -->
    <div class="baogao-jc-detail">
      <div class="list">
        <div class="list-title">项目名称：</div>
        <div class="list-text">{{info.inspectName || '——' }}</div>
      </div>
      <div class="list">
        <div class="list-title">检查日期：</div>
        <div class="list-text">{{info.inspectTime || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查结果：</div>
        <div class="list-text" />
      </div>
      <div class="list-box" v-html="info.inspectResult"></div>
      <!-- <div class="list">
        <div class="list-title">检查医生:</div>
        <div class="list-text">{{info.docName || '——'}}</div>
      </div> -->
      <!-- <div class="list">
        <div class="list-title">部位：</div>
        <div class="list-text">{{info.position}}</div>
      </div> -->
      <!-- <div class="list">
        <div class="list-title">检查描述:</div>
        <div class="list-text">{{info.reportDescribe || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查诊断:</div>
        <div class="list-text">{{info.reportDiagnose || '——'}}</div>
      </div>
      <div class="list">
        <div class="list-title">检查建议:</div>
        <div class="list-text">{{info.reportAdvice || '——'}}</div>
      </div>
    </div> -->
    <!-- <div v-else class="common-wrap fail-wrap">
      数据获取失败,请稍后重试
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'BaoGaoDetail',
  data() {
    return {
      id: '',
      blhNo: '',
      list: [],
      info: {},
      loading: true,
      transportFail: false
    }
  },
  created() {
    const info = localStorage.getItem('baogaoJcDetail')
    if (info) {
      this.info = JSON.parse(info)
    }
    /* this.id = this.$route.query.id
    this.studyId = this.$route.query.studyId */
    // this.getReportDetail()
  },
  mounted() {},
  methods: {
    getReportDetail() {
      // this.$Apis.getInspectReport({ blhNo: this.blhNo }).then(res => {
      //   this.info = res.data.find(item => item.id === this.id)
      // })
      const obj = {
        patientId: this.id
      }
      this.loading = true
      this.$Apis.getInspectReport(obj).then(res => {
        if (res.code === 200) {
          this.info = res.data.find(item => {
            return item.studyId === this.studyId
          })
          this.loading = false
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.transportFail = true
        this.loading = false
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.baogao-jc-detail{
  padding: .1rem .1rem 0;
  background: #fff;
  height: calc(100vh - .1rem);
  .list{
    font-size: .14rem;
    line-height: 1.5;
    padding: .1rem 0;
    &-title{
      float: left;
    }
    &-text{
      margin-left: .7rem;
    }
  }
  .list-box{
    margin-top: .2rem;
  }
}
.common-wrap{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fail-wrap{
  color: #0094ff;
}
</style>
