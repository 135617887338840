<template>
  <div class="yujianfenzhen-item">
    <div class="box-title">江苏省医疗机构患者信息登记</div>
     <div class="box-text">
        <div>尊敬的患者及家属: </div>
        <div class="box-text-indent">为了做好新型冠状病毒肺炎防控工作,根据省卫健委相关疫情防控要求，请于<span class="box-red">就诊当日</span>通过新增登记按钮进行相关信息登记。</div>
        <div class="box-text-indent">信息表登记后仅限<span class="box-red">当天有效</span>，请在进入医疗机构时向预检分诊人员出示。</div>
      </div>
      <div class="box-info" :class="item.state ? 'box-info-red' : ''" v-for="item in list" :key="item.id">
        <div class="box-info-item">
          <span>姓名：</span>
          <span>{{item.name}}</span>
        </div>
        <div class="box-info-item">
          <span>身份证号：</span>
          <span>{{item.sfzh}}</span>
        </div>
        <div class="box-info-item">
          <span>登记时间：</span>
          <span>{{item.createTime}}</span>
        </div>
        <div class="box-info-line" />
        <div class="box-info-btn" @click="toDetail(item.id)">
          <span>详情></span>
        </div>
      </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  created() {
    console.log(this.$attrs)
  },
  methods: {
    toDetail(id) {
      this.$router.push(`/yujianfenzhen?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.yujianfenzhen-item{
  padding: .1rem;
  .box-title{
    font-size: .22rem;
    text-align: center;
    font-weight: bold;
    color: #757575;
    line-height: .24rem;
    padding: .25rem 0;
    border-bottom: 1px solid #ececec;
  }
  .box-text{
    line-height: .2rem;
    font-size: .15rem;
    margin: .1rem 0 .4rem;
    color: #6f6f6f;
    font-weight: 600;
  }
  .box-red{
    color: #ff0600;
  }
  .box-text-indent{
    text-indent: 2em;
    text-align: justify;
  }
  .box-info{
    background: #4cb050;
    color: #fffffd;
    font-weight: 500;
    padding: .15rem .15rem;
    border-radius: .15rem;
    font-size: .15rem;
    margin-bottom: .1rem;
  }
  .box-info-red{
    background: #fe7e7e;
  }
  .box-info-item{
    display: flex;
    justify-content: space-between;
    line-height: .24rem;
  }
  .box-info-line{
    margin: .03rem 0;
    height: .01rem;
    background: #e5e9da;
  }
  .box-info-btn{
    text-align: right;
    line-height: .27rem;
  }
  .box-bom-btn {
    margin-top: .2rem;
  }
  .bom-btn{
    border-radius: .2rem;
  }
}
</style>
