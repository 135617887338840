<template>
  <div class="">
    <div id="container" style="width:100vw;height:100vh;"></div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      longitude: '',
      latitude: ''
    }
  },
  created() {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let address = ''
      var center = new window.qq.maps.LatLng(31.799337, 119.962502)
      var map = new window.qq.maps.Map(document.getElementById('container'), {
        center: center,
        zoom: 16,
        disableDefaultUI: true
      })
      var infoWin = new window.qq.maps.InfoWindow({
        map: map
      })
      var geocoder = new window.qq.maps.Geocoder({
        complete: function(res) {
          address = res.detail.nearPois[0].name
        }
      })
      window.qq.maps.event.addListener(map, 'click', function(event) {
        this.longitude = event.latLng.getLat()
        this.latitude = event.latLng.getLng()
        const lat = new window.qq.maps.LatLng(this.longitude, this.latitude)
        geocoder.getAddress(lat)
        setTimeout(() => {
          infoWin.open()
          infoWin.setContent(
            '<div style="text-align:center;white-space:nowrap;">' +
              address +
              '</div>'
          )
          infoWin.setPosition(event.latLng)
        }, 200)
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped></style>
