<template>
  <div class="baogao-detail">
    <table class="table">
      <tr class="bor">
        <!-- <th></th> -->
        <th>检验项目</th>
        <th>结果</th>
        <th>参考值</th>
        <th>单位</th>
      </tr>
      <tr
        v-for="item in info.list"
        :key="item.id"
        :class="item.flag === 1 ? 'redColor' : ''"
      >
        <!-- <td>{{index+1}}</td> -->
        <td>{{ item.chineseName }}</td>
        <td>{{ item.quantitativeResult }}</td>
        <td>{{ item.testItemReference }}</td>
        <td>{{ item.testItemUnit }}</td>
      </tr>
    </table>
    <!-- <div v-if="loading" class="loading-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "BaoGaoDetail",
  data() {
    return {
      info: {},
    };
  },
  created() {
    const info = localStorage.getItem("outpatientJy");
    if (info) {
      this.info = JSON.parse(info);
    }
  },
  mounted() {},
  methods: {},
};
</script>
<style rel="stylesheet/less" lang="less" scoped>
.redColor {
  color: red;
}
.baogao-detail {
  padding: 0.1rem 0.1rem 0;
  background: #fff;
  height: calc(100vh - 0.1rem);
  .table {
    width: 100%;
    tr {
      border-bottom: 1px solid #ccc;
    }
    th {
      line-height: 0.3rem;
      font-size: 0.14rem;
    }
    td {
      max-width: 50px;
      word-wrap: break-word;
      line-height: 0.3rem;
      font-size: 0.12rem;
    }
  }
}
.loading-wrap {
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
