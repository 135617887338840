<template>
  <div class="mzjf-list">
    <div class="list-item">
      <span class="item-title">科室：</span>
      <span class="item-value">{{ item.deptName }}</span>
    </div>
    <div class="list-item">
      <span class="item-title">就诊医生：</span>
      <span class="item-value">{{ item.doctorName }}</span>
    </div>
    <div class="list-item">
      <span class="item-title">费用名称：</span>
      <span class="item-value">{{ item.chargeName }}</span>
    </div>
    <div class="list-item">
      <span class="item-title">缴费时间：</span>
      <span class="item-value">{{ item.registerTime }}</span>
    </div>
    <div class="list-item">
      <span class="item-title">缴费状态：</span>
      <span class="item-value">{{ item.status | status }} <span>: {{item.chargePrice}}元</span></span>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  filters: {
    status: (val) => {
      switch (val) {
        case '0':
          return '待缴费'
        case '1':
          return '缴费成功'
        case '2':
          return '已作废'
        default:
          return ''
      }
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {}
}
</script>
<style rel="stylesheet/less" lang="less" scoped></style>
