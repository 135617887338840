<template>
  <div class="">
    <van-cell-group>
      <van-cell
        @click="toInfo(1)"
        title="依托高水平医疗机构，加速我院药物临床试验机构成长"
        label="2021-07-01"
      />
      <van-cell
        title="党史学习教育 | “沉浸式”体验，让党史学习入脑入心"
        label="2021-5-18"
        @click="toInfo(2)"
      />
      <van-cell
        title="健康惠民-党员先行｜看！这些医务工作者在干什么？"
        label="2021-5-18"
        @click="toInfo(3)"
      />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  name: '',
  data () {
    return {}
  },
  created () {},
  mounted () {},
  methods: {
    toInfo(id) {
      this.$router.push(`/dynamicInfo?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped></style>
