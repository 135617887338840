<template>
  <div class="add-patient-page">
    <van-form @submit="onSubmit" label-width="5em">
      <van-field
        readonly
        clickable
        name="picker"
        :value="gx"
        label="关系"
        placeholder="请选择关系"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-field
        v-model="username"
        name="姓名"
        label="姓名"
        placeholder="请输入姓名"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        readonly
        clickable
        label="证件类型"
        :value="documentName"
        placeholder="选择证件类型"
        @click="showId = true"
      />
      <van-popup v-model="showId" round position="bottom">
        <van-picker
          show-toolbar
          :columns="columnsId"
          value-key="name"
          @cancel="showId = false"
          @confirm="onConfirmId"
        />
      </van-popup>
      <van-field
        v-model="idCard"
        name="证件号"
        label="证件号"
        placeholder="请输入证件号"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-model="tel"
        name="手机号"
        label="手机号"
        maxlength="11"
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field name="radio" label="性别" :rules="[{ required: true, message: '请选择性别' }]">
        <template #input>
          <van-radio-group v-model="patientSex" direction="horizontal">
            <van-radio name="m">男</van-radio>
            <van-radio name="f">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <p class="tips"><van-icon name="bulb-o" />本院实行实名制就诊，请如实填写就诊人信息，如因信息错误产生的一切后果自行负责</p>
      <van-button round block type="info" native-type="submit" :loading="loading" loading-text="提交中...">提交</van-button>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'AddPatient',
  data() {
    return {
      appletsId: '',
      id: '',
      isNotSelf: '',
      loading: false,
      showPicker: false,
      showId: false,
      columns: [],
      columnsId: [
        { name: '身份证', id: '1' },
        { name: '港澳通行证', id: '2' },
        { name: '护照', id: '3' },
        { name: '军官证', id: '4' },
        { name: '永久居住证', id: '5' },
        { name: '其他', id: '6' }
      ],
      gx: '',
      username: '',
      documentName: '身份证',
      documentType: '1',
      idCard: '',
      idaddtype: 0,
      tel: '',
      patientSex: ''
    }
  },
  created() {
    if (JSON.stringify(this.$route.query) === '{}') return
    const { id, isNotSelf, addtype } = this.$route.query
    this.id = id
    this.addtype = addtype * 1
    this.isNotSelf = isNotSelf * 1
    this.gx = isNotSelf * 1 ? '本人' : '他人'
    this.columns = isNotSelf * 1 ? ['本人'] : ['他人']
    this.appletsId = this.$store.state.openId
  },
  mounted() {},
  methods: {
    onCancel() {},
    onSubmit() {
      this._addPatient()
    },
    _addPatient() {
      const reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
      if (this.documentType * 1 === 1 && !reg.test(this.idCard)) {
        this.$notify({ type: 'warning', message: '身份证格式不正确', duration: 1000 })
        return false
      }
      if (!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel))) {
        this.$notify({ type: 'warning', message: '手机号格式不正确', duration: 1000 })
        return false
      }
      const parms = {
        patient: {
          patientName: this.username,
          patientSfzh: this.idCard,
          documentName: this.documentName,
          documentType: this.documentType,
          patientPhone: this.tel,
          patientSex: this.patientSex
        }
      }
      if (this.isNotSelf) {
        parms.patient.appletsId = this.id
      } else {
        parms.id = this.id
      }
      this.loading = true
      this.$Apis.addPatient(parms).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.$notify({ type: 'success', message: '添加成功', duration: 500 })
          const userInfo = this.$store.state.userInfo
          if (userInfo === null || userInfo === 'null') {
            this.getByAppletsId(1)
            return
          }
          this.$router.go(-1)
        } else {
          this.$dialog.alert({ title: '提示', message: res.message || '添加失败' }).then(() => {})
        }
      }).catch(() => {
        this.loadong = false
      })
    },
    onConfirm(e) {
      this.gx = e
      this.showPicker = false
    },
    getByAppletsId(off) {
      this.$Apis.getByAppletsId({ appletsId: this.appletsId }).then(res => {
        if (res.code === 200) {
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.$store.commit('updateInfo', {
            name: 'userInfo',
            value: res.data
          })
          if (off) this.$router.go(-1)
        } else {
          if (res.message) {
            this.$toast.error(res.message)
          }
        }
      }).catch(err => {
        this.$toast(err)
      })
    },
    onConfirmId(e) {
      this.documentName = e.name
      this.documentType = e.id
      this.showId = false
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.add-patient-page {
  padding: 0.2rem;
  .tips{
    font-size: .14rem;
    margin: 20px 0;
    line-height: 1.5;
    color: #999;
  }
}
</style>
