<template>
  <div class="guahaojilu">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="list.length ? '没有更多了' : ''"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="gj-list" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
        <div class="list-item">
          <span class="item-title">挂号状态：</span>
          <span class="item-value">{{item.status | status}}</span>
        </div>
        <div class="list-item">
          <span class="item-title">挂号时间：</span>
          <span class="item-value">{{item.registerTime}}</span>
        </div>
        <div class="list-item">
          <span class="item-title">挂号科室：</span>
          <span class="item-value">{{item.deptName}}</span>
        </div>
        <div class="list-item">
          <span class="item-title">就诊人名称：</span>
          <span class="item-value">{{item.patientName}}</span>
        </div>
      </div>
    </van-list>
    <no-data v-if="!loading && !list.length"></no-data>
  </div>
</template>

<script>
import noData from '../../components/noData.vue'
export default {
  components: { noData },
  name: 'Guahaojilu',
  filters: {
    status: (val) => {
      switch (val) {
        case 0:
          return '已取消'
        case 1:
          return '已挂号'
        case 2:
          return '已预约'
        default:
          return ''
      }
    }
  },
  data() {
    return {
      patientId: '',
      list: [],
      current: 1,
      pageSize: 10,
      loading: false,
      finished: false
    }
  },
  /* watch: {
    $route: {
      handler: function(to, from) {
        if (from && from.path !== '/guahaoxiangqing') {
          Object.assign(this.$data, this.$options.data())
          this.ageCreated()
        }
      }
    }
  }, */
  created() {
    this.ageCreated()
  },
  mounted() {},
  methods: {
    ageCreated() {
      const userInfo = this.$store.state.userInfo
      if (userInfo && userInfo.id) {
        this.patientId = userInfo.id
        this.getregisterPage()
      }
    },
    getregisterPage() {
      this.loading = true
      const obj = {
        current: this.current,
        size: this.pageSize,
        patientIdList: this.patientId,
        registerType: 1
      }
      this.$Apis.registerPage(obj).then(res => {
        this.loading = false
        if (res.code === 200) {
          this.list = [...this.list, ...res.data.list]
          this.finished = (res.data.totalPage === 0 || this.current === res.data.totalPage)
        } else {
          this.finished = true
        }
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    onLoad() {
      this.current++
      this.getregisterPage()
    },
    toDetail(id) {
      this.$router.push(`/guahaoxiangqing?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped></style>
