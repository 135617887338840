import { render, staticRenderFns } from "./yishengjieshao.vue?vue&type=template&id=1ad6f9f8&scoped=true&"
import script from "./yishengjieshao.vue?vue&type=script&lang=js&"
export * from "./yishengjieshao.vue?vue&type=script&lang=js&"
import style0 from "./yishengjieshao.vue?vue&type=style&index=0&id=1ad6f9f8&rel=stylesheet%2Fless&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad6f9f8",
  null
  
)

export default component.exports