<template>
  <div class="main-container">
    <van-form @submit="onSubmit">
      <van-field
        v-model="name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="idCard"
        name="身份证号"
        label="身份证号"
        placeholder="身份证号"
        :rules="[{ required: true, message: '请填写身份证号' }]"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">查询</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      name: '',
      idCard: ''
    }
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit() {
      const searchForm = {
        name: this.name,
        idCard: this.idCard
      }
      this.$router.push({
        path: '/hesuanbaogao/list',
        query: {
          searchForm
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .main-container{
    padding: 0.2rem;
  }
</style>
