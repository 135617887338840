<template>
  <div class="main-container">
    <div v-if="loading" class="loading-wrap">
      <van-loading size="24px" color="#0094ff" vertical>加载中...</van-loading>
    </div>
    <div v-else-if="!loading && showData">
      <div class="info">
        <div class="patient-card">
          <div class="patient-name">{{ patientName || '——' }}</div>
          <div class="sex">{{ sex }}</div>
          <div class="age">{{ age }}</div>
        </div>
        <div class="info-item">
          <div class="item date">
            <van-icon name="notes-o" />
            <div class="item-msg">
              <div class="msg">{{ dateCount || '——' }}</div>
              <div class="msg-desc">住院天数（天）</div>
            </div>
          </div>
          <!-- <div class="line"></div> -->
          <div class="item pay">
            <van-icon name="balance-list-o" />
            <div class="item-msg">
              <div class="msg">{{ total || '——' }}</div>
              <div class="msg-desc">住院费用（元）</div>
            </div>
          </div>
        </div>
      </div>
      <div class="list">
        <div v-for="(item,index) in list" :key="index" class="list-item" @click="goDetail(item)">
          <div class="list-item-date">{{ item.date }}</div>
          <div class="flex"></div>
          <div class="cost">{{ `￥${item.totalCosts}` }}</div>
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
    <div v-else class="my-line">
      <img class="no-patient-pic" src="~@/assets/images/no-content.png" alt />
      <p>暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      searchForm: {},
      list: [],
      sex: '',
      age: '',
      total: '',
      dateCount: '',
      patientName: '',
      showData: false,
      loading: true
    }
  },
  created() {
    this.searchForm = JSON.parse(sessionStorage.zhuyuanSearchForm)
    this.getPatientInfo()
    // this.getList()
  },
  mounted() {},
  methods: {
    getPatientInfo() {
      const params = {
        parentName: this.searchForm.patientName,
        patCardNumber: this.searchForm.inHosNum,
        patientSfzh: this.searchForm.idCard
      }
      this.$Apis.getPatientZy(params).then(res => {
        if (res.code === 200) {
          this.sex = res.data.sex
          this.age = res.data.age
          this.total = res.data.totalFee
          this.dateCount = res.data.dateCount
          this.patientName = res.data.name
          if (res.data.admissionNum) {
            this.getList(res.data.admissionNum)
            this.loading = false
            this.showData = true
          }
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.loading = false
      })
    },
    getList(patCardNumber) {
      const params = {
        patCardNumber
      }
      this.$Apis.getPatientZyOrderDetail(params).then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    goDetail(item) {
      this.$router.push({
        path: '/zhuyuan/list/detail',
        query: {
          currentDate: item.date,
          detailList: this.list
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main-container{
  min-height: 100vh;
}
.info {
  padding: 0.2rem;
  background-color: #fff;
}
.patient-card {
  box-sizing: border-box;
  height: 80px;
  background-image: url("../../assets/images/patient-card_bg.png");
  background-size: 110% 110%;
  background-position: 60% 20%;
  color: #fff;
  display: flex;
  align-items: flex-end;
  padding: 0.3rem;
  border-radius: 6px;
  .patient-name {
    font-size: 24px;
    margin-right: 12px;
  }
  .sex {
    margin-right: 12px;
  }
}
.info-item {
  margin-top: 24px;
  display: flex;
  .item {
    padding: 0 0.1rem;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    align-items: center;
    .msg {
      font-size: 20px;
      margin-bottom: 4px;
    }
    .msg-desc {
      font-size: 12px;
      color: #666;
    }
  }
}
.line {
  height: 100%;
  width: 1px;
  background-color: #f1f2f3;
}
.item {
  /deep/ .van-icon {
    font-size: 30px;
    margin-right: 12px;
    color: #0094ff;
  }
}
.date {
  border-right: 1px solid #ccc;
}
.list {
  margin-top: 12px;
  background-color: #fff;
  padding: 0 0.2rem;
  box-sizing: border-box;
  .list-item {
    box-sizing: border-box;
    height: 54px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
    .flex {
      flex: 1;
    }
    .cost {
      color: #f33;
    }
  }
  .list-item:last-of-type {
    border-bottom: none;
  }
  /deep/ .van-icon-arrow {
    color: #999;
    font-size: 20px;
  }
}
.my-line {
  text-align: center;
  margin-top: 1rem;
  p{
    line-height: 1rem;
  }
}
.loading-wrap{
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
