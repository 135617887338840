<template>
  <div class="timeCell-box" @click="toQueren">
    <van-cell-group>
      <van-cell
        v-for="item in list"
        :key="item.id"
        :title="item.noon | noon"
        :label="`${item.startTime}${item.endTime ? ('-' + item.endTime) : '' }`"
        is-link
        center
        @click="toQueren(item)"
      >
        <span slot="default">余号:{{item.keepQuota}}&emsp;&emsp;￥{{item.registerMoney}}</span>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'timeCell',
  filters: {
    noon: (val) => {
      switch (val) {
        case '1':
          return '上午'
        case '2':
          return '下午'
        case '3':
          return '全天'
        default:
          return ''
      }
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    toQueren(item) {
      if (item.isTrusted === true) return
      localStorage.setItem('yuyueInfo', JSON.stringify(item))
      this.$router.push('/guahaoqueren')
    }
  }
}
</script>

<style scoped lang="less">
  /deep/.van-cell__value {
    color: #fca237;
  }
</style>
