export const checkAllIcon = [
  {
    label: ["五官科"],
    icon: require("../../../assets/images/report/checkAll/icon001.png"),
  },
  {
    label: ["血脂4项"],
    icon: require("../../../assets/images/report/checkAll/icon002.png"),
  },
  {
    label: ["血糖"],
    icon: require("../../../assets/images/report/checkAll/icon003.png"),
  },
  {
    label: ["肾功能3项"],
    icon: require("../../../assets/images/report/checkAll/icon004.png"),
  },
  {
    // label: "糖化血红蛋白(HbA1C)",
    label: ["糖化血红蛋白"],
    icon: require("../../../assets/images/report/checkAll/icon005.png"),
  },
  {
    label: ["一般情况"],
    icon: require("../../../assets/images/report/checkAll/icon006.png"),
  },
  {
    label: ["内科"],
    icon: require("../../../assets/images/report/checkAll/icon007.png"),
  },
  {
    label: ["外科"],
    icon: require("../../../assets/images/report/checkAll/icon008.png"),
  },
  {
    label: ["眼科"],
    icon: require("../../../assets/images/report/checkAll/icon009.png"),
  },
  {
    label: ["女性肿瘤7项"],
    icon: require("../../../assets/images/report/checkAll/icon010.png"),
  },
  {
    label: ["消化内科"],
    icon: require("../../../assets/images/report/checkAll/icon011.png"),
  },
  {
    label: ["骨密度"],
    icon: require("../../../assets/images/report/checkAll/icon012.png"),
  },
  {
    // label: "超敏C反应蛋白(hs-CRP)",
    label: ["超敏C反应蛋白"],
    icon: require("../../../assets/images/report/checkAll/icon013.png"),
  },
  {
    label: ["尿常规"],
    icon: require("../../../assets/images/report/checkAll/icon014.png"),
  },
  {
    // label: "彩色超声（肝胆脾胰肾）",
    label: ["彩色超声"],
    icon: require("../../../assets/images/report/checkAll/icon015.png"),
  },
  {
    // label: "彩色超声（甲状腺）",
    label: ["彩色超声"],
    icon: require("../../../assets/images/report/checkAll/icon016.png"),
  },
  {
    // label: "彩色超声（乳腺）",
    label: ["彩色超声"],
    icon: require("../../../assets/images/report/checkAll/icon017.png"),
  },
  {
    label: ["胸部CT平扫"],
    icon: require("../../../assets/images/report/checkAll/icon018.png"),
  },
  {
    label: ["白带常规3项"],
    icon: require("../../../assets/images/report/checkAll/icon019.png"),
  },
  {
    label: ["常规心电图"],
    icon: require("../../../assets/images/report/checkAll/icon020.png"),
  },
  {
    label: ["HPV1618"],
    icon: require("../../../assets/images/report/checkAll/icon021.png"),
  },
  {
    label: ["肝功能13项"],
    icon: require("../../../assets/images/report/checkAll/icon022.png"),
  },
  {
    label: ["胆碱酯酶", "腺苷脱氨酶"],
    icon: require("../../../assets/images/report/checkAll/icon023.png"),
  },
  {
    // label: "彩色超声：子宫附件（经腔内)",
    label: ["彩色超声", "子宫附件"],
    icon: require("../../../assets/images/report/checkAll/icon024.png"),
  },
  {
    label: ["血常规"],
    icon: require("../../../assets/images/report/checkAll/icon025.png"),
  },
  {
    label: ["TCT检测"],
    icon: require("../../../assets/images/report/checkAll/icon026.png"),
  },
  {
    label: ["彩色超声", "前列腺"],
    icon: require("../../../assets/images/report/checkAll/icon026.png"),
  },
  {
    // label: "甲状腺功能三项（TSH，T3,T4）",
    label: ["甲状腺功能三项"],
    icon: require("../../../assets/images/report/checkAll/icon028.png"),
  },
  {
    // label: "彩色超声：子宫附件（经腹)",
    label: ["彩色超声", "子宫附件"],
    icon: require("../../../assets/images/report/checkAll/icon029.png"),
  },
  {
    label: ["男性肿瘤7项"],
    icon: require("../../../assets/images/report/checkAll/icon030.png"),
  },
];
