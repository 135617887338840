<template>
  <div class="menzhenjiaofeijilu">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="list.length ? '没有更多了' : ''"
      :immediate-check="false"
      @load="onLoad"
    >
      <div class="view-box-container" v-for="item in list" :key="item.id">
        <div class="view-box" :class="item.status==='2' ? 'view-bj' : ''">
          <div class="view-text-box" v-if="item.jslx !== '1' && item.jslx!=='2'">
            <span class="view-item">科室：</span>
            <span class="view-text">{{item.deptName}}</span>
          </div>
          <div class="view-text-box" v-if="item.jslx !== '1' && item.jslx!=='2'">
            <span class="view-item">就诊医生：</span>
            <span class="view-text">{{item.doctorName}}</span>
          </div>
          <div class="view-text-box">
            <span class="view-item">费用名称：</span>
            <span class="view-text">{{item.chargeName}}</span>
          </div>
          <div class="view-text-box">
            <span class="view-item">缴费时间：</span>
            <span class="view-text">{{item.chargeTime}}</span>
          </div>
          <div class="view-text-box">
            <span class="view-item">缴费状态：</span>
            <span class="view-text">
              <span class="view-text-box view-text-success" v-if="item.status==='0'">未交费</span>
              <span class="view-text-box view-text-success" v-if="item.status==='1'">缴费成功</span>
              <span class="view-text-box view-text-fail" v-if="item.status==='2'">已作废</span>
            </span>
          </div>
        </div>
        <div class="box-bottom">
          <div v-if="item.status==='1' || item.status==='2'" class="bom-btn" @click="todzpz(item.id)">电子凭证</div>
          <!-- <div class="bom-btn" @click="toMingxi(item.clinicNo)">缴费明细</div> -->
        </div>
      </div>
    </van-list>
    <NoData v-if="!loading && list.length === 0"></NoData>
  </div>
</template>

<script>
export default {
  name: 'MenZhenJiaoFeiJiLu',
  // components: {
  //   pdf
  // },
  data() {
    return {
      current: 1,
      pageSize: 10,
      patientIds: '',
      list: [],
      loading: false,
      finished: false
    }
  },
  created() {
    const userInfo = this.$store.state.userInfo
    if (!userInfo && userInfo !== 'null') {
      this.patientIds = userInfo && userInfo.id
      this.getList()
    }
  },
  mounted() {},
  methods: {
    getList() {
      this.loading = true
      const obj = {
        patientIds: this.patientIds,
        current: this.current,
        size: this.pageSize
        // status: '1;2'
      }
      this.$Apis.chargeListPage(obj).then(res => {
        this.loading = false
        if (res.code === 200) {
          const { current, totalPage, list } = res.data
          this.list = [...this.list, ...list]
          this.finished = (totalPage === 0 || current === totalPage)
        } else {
          this.finished = true
        }
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    onLoad() {
      this.current++
      this.getList()
    },
    todzpz(id) {
      this.$Apis.getEinvoiceDetailsById({ Id: id }).then(res => {
        if (res.code === 200) {
          if (res.data.dzpjurl) {
            window.location.href = res.data.dzpjurl
          }
        } else {
          this.$toast(res.message)
        }
      })

      // this.$router.push(`/dianzipingzheng?id=${id}`)
      // this.$Apis.getEinvoiceDetailsById({ Id: id }).then(res => {
      //   if (res.code === 200) {
      //     // window.location.href = res.data.dzpjurl
      //     // window.open(res.data.dzpjurl, '_self')
      //     const link = document.createElement('a')
      //     link.id = 'dzpjurl'
      //     // link.target = '_blank'
      //     link.href = res.data.dzpjurl
      //     document.body.appendChild(link)
      //     link.click()
      //     document.body.removeChild(link)
      //   } else {
      //     this.$toast(res.message)
      //   }
      // })
    },
    toMingxi(id) {
      this.$router.push(`/jiaofeimingxi?id=${id}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.view-box-container {
  padding: .05rem 0;
  margin-top: .05rem;
  margin-bottom: .05rem;
  background: #ffffff;
  border-radius: .04rem;
}

.view-bj{
  background: url('/images/yizuofei.png') no-repeat right .2rem top .1rem / .5rem .5rem;
}

.view-box{
  margin-top: .1rem;
}

.view-text-box {
  margin-left: .1rem;
  line-height: 1.2;
  font-size: .14rem;
  margin-bottom: .075rem;
}

.view-text-success {
  color: #1078FE;
}

.view-text-fail {
  color: red;
}

.box-right {
  margin-right: .2rem;
}

.box-right .btn {
  background: #1777FF;
  padding: .03rem .05rem;
  border-radius: .015rem;
  font-size: .14rem;
  color: #fff;
  z-index: 999;
}

.box-bottom {
  padding: 0 .15rem .05rem;
  text-align: right;
}

.bom-btn {
  background: #1777FF;
  padding: .03rem .05rem;
  border-radius: .03rem;
  font-size: .14rem;
  color: #fff;
  display: inline-block;
  margin-right: .1rem;
}
</style>
