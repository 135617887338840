<template>
  <div>
    <div class="checkAll-box">
      <van-collapse v-model="activeName" class="checkAll-box-content">
        <van-collapse-item
          v-for="(item, index) in detailsAll.czrhDeptList"
          :key="index"
          :title="item.title"
          :name="item.index"
        >
          <template #icon>
            <img :src="getImage(item).icon" alt="" />
          </template>
          <template #title>
            <div class="list-title">
              <div class="list-title-name">
                {{ item.combinName }}
              </div>
              <div class="list-title-unusual" v-if="item.num > 0">
                异常<span>{{ item.num }}</span>
              </div>
            </div>
          </template>
          <template #right-icon>
            <div class="right-icon">
              <img src="@/assets/images/icons/arrow-icon.png" alt="" />
            </div>
          </template>
          <!-- 一般项 -->
          <template v-if="item.czrhDeptType === 1">
            <ul class="generalSituation">
              <li
                v-for="(setItem, index) in item.childrenItem.jcResult"
                :key="index"
              >
                <div>{{ setItem.chineseName }}</div>
                <div>{{ setItem.quantitativeResult }}</div>
              </li>
            </ul>
          </template>
          <!-- 检查类（心电图） -->
          <template v-if="item.czrhDeptType === 3">
            <div class="electrocardiogram">
              <div class="electrocardiogram-top">
                <div>小结</div>
                <div>{{ item.childrenItem.xdtResult.xdBriefSummary }}</div>
              </div>
              <div class="electrocardiogram-bottom">
                <div class="electrocardiogram-bottom-title">图片</div>
                <!-- <ul class="electrocardiogram-bottom-list">
                  <li>
                    <div>心率<span>88 bpm</span></div>
                    <div>QRS间期*<span>17</span></div>
                  </li>
                  <li>
                    <div>心率<span>88 bpm</span></div>
                    <div>QRS间期*<span>17</span></div>
                  </li>
                  <li>
                    <div>心率<span>88 bpm</span></div>
                    <div>QRS间期*<span>17</span></div>
                  </li>
                </ul>
                <ul class="electrocardiogram-bottom-size">
                  <li>速度：25 mm/sec</li>
                  <li>增益：10 mm/mv</li>
                </ul> -->
                <div class="electrocardiogram-bottom-img">
                  <img :src="item.childrenItem.xdtResult.xdImg" alt="" />
                  <!-- <img src="../../../assets/images/report/test001.png" alt="" /> -->
                </div>
              </div>
            </div>
          </template>
          <!-- 检查类 -->
          <template v-if="item.czrhDeptType === 4">
            <div class="detectionClass">
              <ul class="detectionClass-top">
                <li>小结</li>
                <li>{{ item.childrenItem.csResult.csBriefSummary }}</li>
              </ul>
              <ul class="detectionClass-content">
                <li>检查结果</li>
                <li>
                  {{ item.childrenItem.csResult.csInspectionResult }}
                </li>
              </ul>
              <ul class="detectionClass-bottom">
                <li>图片</li>
                <li>
                  <div class="detectionClass-bottom-box-image" style="width:100%;height:230px;">
                    <img :src="item.childrenItem.csResult.csImg" alt="" />
                    <!-- <img
                      src="../../../assets/images/report/test002.png"
                      alt=""
                    /> -->
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <!-- 检验类 -->
          <template v-if="item.czrhDeptType === 2">
            <ul
              class="testClass"
              v-for="(checkItem, index) in item.childrenItem.jyResult"
              :key="index"
            >
              <li>
                <span>{{ checkItem.chineseName }}</span
                >&nbsp;&nbsp;&nbsp;<span>({{ checkItem.testItemUnit }})</span>
              </li>
              <li :class="checkItem.flag === '0' ? '' : 'selectColor'">
                {{ checkItem.quantitativeResult }}
              </li>
              <li>正常值范围:{{ checkItem.testItemReference }}</li>
            </ul>
          </template>
        </van-collapse-item>
      </van-collapse>
      <div class="checkAll-btn">
        <van-button :disabled="this.disabledBtn" type="info" @click="download" :loading="loading" loading-text="下载中...">下载报告</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { checkAllIcon } from "./iconlist";

export default {
  props: {
    detailsAll: {
      type: Object,
      default: () => ({}),
    },
    cardNo: {
      type: String,
    },
    disabledBtn: {
      type: Boolean,
    }
  },
  data() {
    return {
      loading: false,
      activeName: ["01"],
    };
  },
  created() {},
  computed: {},
  methods: {
    // 通过关键字匹配图片
    getImage(item) {
      // 匹配 combinName 包含所有 label中 关键字的
      return (
        checkAllIcon.find((i) =>
          i.label.every((li) => item.combinName.includes(li))
        ) || {
          icon: require("../../../assets/images/report/checkAll/icon031.png"),
        }
      );
    },
    download() {
      const data = {
        cardNo: this.cardNo
      }
      this.loading = true
      this.$Apis.getCzrhTjReportPdf(data).then((res) => {
        this.loading = false
        if (res.code === 200) {
          const downloadLink = document.createElement('a')
          const url = res.data
          downloadLink.href = url
          downloadLink.download = '检查报告.pdf'
          document.body.appendChild(downloadLink)
          downloadLink.click()
        } else {
          this.$toast.fail(res.message);
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.checkAll-box {
  margin-top: 16px;
}
/deep/.van-collapse-item__title {
  position: sticky;
  top: 0;
}
.checkAll-btn {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom:0;
  background:#f5f7fc;
  padding:16px 0px;
  display: flex;
  justify-content: center;
  button {
    width: calc(100% - 24px * 2);
    border-radius: 20px;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }
}
.checkAll-box-content {
  padding-bottom: 80px;
}
.list-left-icon {
  width: 44px;
  height: 44px;
}
.list-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
  height: 44px;
  // .list-title-unusual {
  //   font-size: 12px;
  // }
  &-name {
    font-size: 15px;
    margin-right: 8px;
    line-height: 18px;
  }
  &-unusual {
    background: #fc5e52;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    padding: 2px 10px;
    border-radius: 14px 4px 14px 4px;
  }
}
.right-icon {
  display: flex;
  align-items: center;
  transition: transform 0.2s;
  transform: rotate(-90deg);
  img {
    width: 24px;
    height: 24px;
  }
}
/deep/.van-collapse-item__title--expanded {
  .right-icon {
    transform: rotate(0deg);
  }
}
// 一般项
.generalSituation {
  padding: 16px 46px;
  background: #ecf5fe;
  font-size: 16px;
  color: #666;
  li {
    display: flex;
    text-align: left;
    div:nth-of-type(1) {
      min-width: 96px;
      margin-right: 80px;
    }
    div:nth-of-type(2) {
      min-width: 66px;
    }
  }
}
// 常规心电图
.electrocardiogram {
  .electrocardiogram-top {
    // background: #e6f2fe;
    div:nth-of-type(1) {
      font-size: 15px;
      padding: 12px 16px;
      border: 1px dashed #969798;
      color: #333;
      background: #e6f2fe;
    }
    div:nth-of-type(2) {
      padding: 12px 16px;
      font-size: 12px;
      border: 1px dashed #969798;
      border-top: 0px;
      color: #777;
      background: #ecf5fe;
    }
  }
  .electrocardiogram-bottom {
    margin-top: 10px;
    .electrocardiogram-bottom-title {
      font-size: 15px;
      padding: 12px 16px;
      border-bottom: 0px;
      border-top: 0px;
      color: #333;
      border: 1px dashed #969798;
      background: #e6f2fe;
    }
    .electrocardiogram-bottom-list {
      border: 1px dashed #969798;
      border-top: 0px;
      border-bottom: 0px;
      background: #e6f2fe;
      li {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border-bottom: 0px;
        font-size: 12px;
        color: #777;
      }
    }
    .electrocardiogram-bottom-size {
      display: flex;
      justify-content: center;
      font-size: 12px;
      border: 1px dashed #969798;
      border-top: 0px;
      border-bottom: 0px;
      color: #fc4c4c;
      background: #e6f2fe;
      // margin-bottom:15px;
      li:nth-of-type(1) {
        margin-right: 8px;
        padding-bottom: 14px;
      }
    }
    .electrocardiogram-bottom-img {
      border: 1px dashed #969798;
      border-top: 0px;
      padding: 12px;
      width: 100%;
      height: 425px;
      // background: #eee;
      box-sizing: border-box;
      background: #e6f2fe;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
// 检测类
.detectionClass {
  .detectionClass-top {
    li:nth-of-type(1) {
      background: #e6f2fe;
      padding: 12px 16px;
      font-size: 15px;
      padding: 12px 16px;
      border: 1px dashed #969798;
      color: #333;
    }
    li:nth-of-type(2) {
      font-size: 12px;
      border: 1px dashed #969798;
      border-top: 0px;
      padding: 12px 16px;
      color: #777;
      background: #ecf5fe;
    }
  }
  .detectionClass-content {
    margin-top: 14px;
    li:nth-of-type(1) {
      background: #e6f2fe;
      padding: 12px 16px;
      font-size: 15px;
      padding: 12px 16px;
      border: 1px dashed #969798;
      color: #333;
    }
    li:nth-of-type(2) {
      font-size: 12px;
      border: 1px dashed #969798;
      border-top: 0px;
      padding: 12px 16px;
      color: #777;
      background: #ecf5fe;
    }
  }
  .detectionClass-bottom {
    margin-top: 14px;
    li:nth-of-type(1) {
      background: #e6f2fe;
      padding: 12px 16px;
      font-size: 15px;
      padding: 12px 16px;
      border: 1px dashed #969798;
      color: #333;
    }
    li:nth-of-type(2) {
      border: 1px dashed #969798;
      border-top: 0px;
      // padding: 12px 54px;
      background: #ecf5fe;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        margin: 20px 0px;
        width: 219px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
// 检验类
.testClass {
  margin-top: 12px;
  .selectColor {
    color: #fb5555 !important;
  }
  li:nth-of-type(1) {
    background: #e6f2fe;
    padding: 12px 16px;
    font-size: 15px;
    padding: 12px 16px;
    color: #333;
    border-radius: 5px 5px 0px 0px;
  }
  li:nth-of-type(2) {
    background: #ecf5fe;
    padding: 16px 16px 12px;
    font-size: 19px;
    font-weight: bold;
    padding: 12px 16px;
    color: #333;
  }
  li:nth-of-type(3) {
    background: #ecf5fe;
    padding: 12px 16px;
    font-size: 12px;
    color: #777;
    border-radius: 0px 0px 5px 5px;
  }
}
</style>
