<template>
  <div class="pay-page">
    <van-cell-group>
      <van-cell title="提示：该服务只支持自费患者，暂不支持医保支付。" />
      <van-cell title="支付类型" :value="feeType === '1' ? '核酸检测' : '抗原检测'" />
      <van-cell title="检测时段" value="支付当日" />
      <van-cell v-if="feeType === '1'" title="检测类型">
        <template #default>
          <van-radio-group v-model="type" direction="horizontal" style="justify-content: flex-end;">
            <van-radio name="1">单采</van-radio>
            <van-radio name="2">混采</van-radio>
          </van-radio-group>
        </template>
      </van-cell>
      <van-cell v-if="feeType === '1'" class="item-val" title="检测金额" :value="`￥${type === '1' ? (detail.dcPrice || '') : (detail.hcPrice || '')}`" />
      <van-cell v-else class="item-val" title="检测金额" :value="`￥${detail.kyPrice || ''}`" />
    </van-cell-group>
    <selectPatient :key="patientKey" @selectChange="selectChange" />
    <van-button
      :loading='btnLoading'
      type="info"
      class="bottom-btn"
      :disabled="choosePatient === ''"
      @click="onPay"
    >立即支付</van-button>
  </div>
</template>
<script>
import selectPatient from '../../components/selectPatient'
import mixin from '../../mixins'
export default {
  name: 'Pay',
  mixins: [mixin],
  components: {
    selectPatient
  },
  data () {
    return {
      openId: '',
      feeType: '',
      type: '1',
      btnLoading: false,
      choosePatient: '',
      patientKey: 0,
      detail: {}
    }
  },
  created () {
    this.feeType = this.$route.query.feeType
  },
  mounted () {
    setTimeout(() => {
      this.getHSPrice()
    }, 100)
  },
  methods: {
    resetCreated() {
      this.patientKey++
    },
    // 选择就诊人
    selectChange(item) {
      this.choosePatient = item.id
    },
    onPay() {
      // if (this.activeData.length === 0) return
      const openId = this.$store.state.openId
      let data = {}
      if (this.feeType === '1') {
        data = {
          // ids: this.activeData.join(','),
          type: this.type,
          buyId: openId,
          patientId: this.choosePatient,
          chargeType: this.feeType
        }
      } else {
        data = {
          patientId: this.choosePatient
        }
      }

      this.btnLoading = true
      const requestName = this.feeType === '1' ? this.$Apis.wechatPay : this.$Apis.wechatPayKy
      requestName(data).then(res => {
        this.btnLoading = false
        if (res.code === 200) {
          // location.href = res.data
          this.wxPay(res.data.body)
        } else {
          this.$toast(res.message)
        }
      }).catch(err => {
        this.btnLoading = false
        this.$toast(err.message)
      })
    },
    wxPay(data) {
      const { appId, nonceStr, paySign, signType, timeStamp } = data
      const that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
      function onBridgeReady() {
        WeixinJSBridge.invoke( // eslint-disable-line no-undef
          'getBrandWCPayRequest', {
            appId, // 公众号名称，由商户传入
            timeStamp, // 时间戳，自1970年以来的秒数
            nonceStr, // 随机串
            package: `${data.package}`,
            signType, // 微信签名方式：
            paySign // 微信签名
          }, function(res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') { // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              // this.$router.go(-1)
              that.$router.push('/menzhenjiaofeijilu')
            } else if (res.err_msg === 'get_brand_wcpay_request:cancel') {
              that.$toast('已取消支付！')
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              that.$toast('支付失败了！')
            }
          }
        )
      }
    },
    getHSPrice() {
      const name = this.feeType === '1' ? this.$Apis.getHSPrice : this.$Apis.getKYPrice
      name().then(res => {
        if (res.code === 200) {
          this.detail = res.data
        }
      })
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.bottom-btn{
  display: block;
    width: 90%;
    margin: .2rem auto;
    border-radius: .2rem;
}
</style>
