<template>
  <div class="main-container">
    <div class="list-box" v-if="list.length">
      <div v-for="item in list" :key="item.id" class="list-item">
        <div class="left">
          <div class="title">{{ item.inspectName }}</div>
          <div class="time">
            <span class="time-title">报告时间：</span>
            {{ item.inspectTime }}
          </div>
        </div>
        <div class="right">
          <!-- <i class="circle" />
          <span>已发布</span>-->
          <van-button class="btn preview" size="mini" @click="preview(item)">预览</van-button>
          <!-- <van-button class="btn download" size="mini" @click="download(item)">下载</van-button> -->
        </div>
      </div>
    </div>
    <div v-else class="no-info">
      <img class="no-patient-pic" src="~@/assets/images/no-content.png" alt />
      <p>暂未获取到核酸报告信息</p>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      searchForm: {},
      list: []
    }
  },
  created() {
    if (this.$route.query.searchForm) {
      this.searchForm = this.$route.query.searchForm
      this.getList('byName')
    } else {
      this.getList('byId')
    }
  },
  mounted() {},
  methods: {
    getList(type) {
      if (type === 'byId') {
        this.$Apis.getNucleicAcidTestReport({ patientId: this.$route.query.id }).then(res => {
          if (res.code === 200) {
            this.list = res.data
          }
        })
      } else if (type === 'byName') {
        const params = {
          patientName: this.searchForm.name,
          sfzh: this.searchForm.idCard
        }
        this.$Apis.getNucleicAcidTestReport(params).then(res => {
          if (res.code === 200) {
            this.list = res.data
          }
        })
      } else {
        this.$toast('未正确获取数据，请重试')
        this.$router.go(-1)
      }
    },
    preview(item) {
      window.location.href = item.inspectResult
    },
    download(item) {
      const downloadLink = document.createElement('a')
      // const URL = window.URL || window.webkitURL || window.moxURL
      const url = item.inspectResult
      downloadLink.href = url
      downloadLink.download = `${this.hzxm}检查报告.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
    }
  }
}
</script>

<style lang="less" scoped>
.list-item {
  height: 56px;
  background-color: #fff;
  // border-bottom: 1px solid #f1f2f3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.1rem;
  margin: 0.1rem;
  padding: 0.1rem;
  .left {
    height: 100%;
    padding-left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .time {
      color: #666;
      font-size: 12px;
      .time-title {
        color: #999;
      }
    }
  }
  .right {
    padding-right: 6px;
    .circle {
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: rgb(104, 196, 96);
      margin-right: 4px;
    }
    .btn {
      color: #fff;
      padding: 8px 12px;
      border-radius: 4px;
      border: none;
    }
    .preview {
      background-color: #61affe;
    }
    .download {
      background-color: #ffcd17;
    }
  }
}
.no-info {
  text-align: center;
  margin-top: 1rem;
  p {
    line-height: 1rem;
  }
}
</style>
