<template>
  <div class="baogao-list">
    <div class="title" @click="showCalendar=true">请选择日期<span v-if="date">:</span>{{date}}<van-icon v-if="!date" class="iconclass" name="play" /></div>
    <van-calendar
      :min-date="minDate"
      :max-date="maxDate"
      :style="{ height: '70vh' }"
      v-model="showCalendar"
      type="range"
      :allow-same-day="true"
      color="#1989fa"
      @confirm="onConfirm"
    />
    <van-tabs v-model="activeName">
      <van-tab title="检查项目" name="1">
        <van-list
          v-model="jcLoading"
          :finished="jcFinished"
          :finished-text="jcList.length ? '' : '暂无数据'"
          :immediate-check="false"
          @load="jcOnLoad"
        >
          <div class="gj-list" v-for="item in jcList" :key="item.id" @click="toJcDetail(item)">
            <div class="list-item">
              <span class="item-title">检查项目：</span>
              <span class="item-value">{{item.inspectName}}</span>
            </div>
            <div class="list-item">
              <span class="item-title">报告时间：</span>
              <span class="item-value">{{item.inspectTime}}</span>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="检验项目" name="2">
        <van-list
          v-model="jyLoading"
          :finished="jyFinished"
          :finished-text="jyList.length ? '' : '暂无数据'"
          :immediate-check="false"
          @load="jyOnLoad"
        >
        <div class="gj-list" v-for="item in jyList" :key="item.id" @click="toDetail(item)">
            <div class="list-item">
              <span class="item-title">检验项目：</span>
              <span class="item-value">{{item.inspectionName}}</span>
            </div>
            <div class="list-item">
              <span class="item-title">报告时间：</span>
              <span class="item-value">{{item.reportTime}}</span>
            </div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'BaogaoList',
  data() {
    return {
      date: '',
      showCalendar: false,
      minDate: new Date('2020/1/1'),
      maxDate: new Date(),
      startDate: '',
      endDate: '',
      hisPatientId: '',
      id: '',
      jyLoading: false,
      jcLoading: false,
      jyFinished: false,
      jcFinished: false,
      activeName: '1',
      jcCurrent: 1,
      jyCurrent: 1,
      pageSize: 10,
      jcList: [],
      jyList: []
    }
  },
  watch: {
    $route: {
      handler: function(to, from) {
        if (from && from.path === '/myPatient') {
          Object.assign(this.$data, this.$options.data())
          this.ageCreated()
        }
      }
    }
  },
  created() {
    this.ageCreated()
  },
  mounted() {},
  methods: {
    ageCreated() {
      const startDate = this.formatDate(new Date(new Date().getTime() - 30 * 24 * 3600000))
      const endDate = this.formatDate(new Date())
      this.date = `${startDate}~${endDate}`
      this.startDate = startDate
      this.endDate = endDate
      this.id = this.$route.query.id
      this.blhNo = this.$route.query.blhNo
      this.getList()
    },
    formatDate(date) {
      return `${date.getFullYear()}-${((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1)}-${(date.getDate() > 9 ? '' : '0') + date.getDate()}`
    },
    onConfirm(date) {
      const [start, end] = date
      this.showCalendar = false
      this.date = `${this.formatDate(start)}/${this.formatDate(end)}`
      this.startDate = this.formatDate(start)
      this.endDate = this.formatDate(end)
      this.getList()
    },
    getList() {
      this.getInspectListPage()
      this.getInspectionListPage()
    },
    // 检查报告
    getInspectListPage() {
      this.jcLoading = true
      const obj = {
        patientId: this.id,
        startTime: `${this.startDate}`,
        endTime: `${this.endDate}`
      }
      this.$Apis.getMZInspectReport(obj).then(res => {
        this.jcLoading = false
        if (res.code === 200) {
          this.jcList = res.data
          this.jcFinished = true
        } else {
          this.jcFinished = true
        }
      }).catch(() => {
        this.jcLoading = false
        this.jcFinished = true
      })
    },
    // 检验报告
    getInspectionListPage() {
      this.jyLoading = true
      const obj = {
        patientId: this.id || '',
        startTime: `${this.startDate}`,
        endTime: `${this.endDate}`
      }
      this.$Apis.getMZJYReportList(obj).then(res => {
        this.jyLoading = false
        if (res.code === 200) {
          this.jyList = res.data
          this.jyFinished = true
        } else {
          this.jyFinished = true
        }
      }).catch(() => {
        this.jyLoading = false
        this.jyFinished = true
      })
    },
    jcOnLoad() {
      this.jcCurrent++
      this.getInspectListPage()
    },
    jyOnLoad() {
      this.jyCurrent++
      this.getInspectionListPage()
    },
    toJcDetail(item) {
      localStorage.setItem('baogaoJcDetail', JSON.stringify(item))
      this.$router.push(`/baogaoJcDetail?id=${this.id}&studyId=${item.studyId}`)
    },
    toDetail(item) {
      this.$router.push(`/baogaoDetail?id=${this.id}&inspectionId=${item.inspectionId}`)
    }
  }
}
</script>
<style rel="stylesheet/less" lang="less" scoped>
.title{
  margin-bottom: .1rem;
  padding: .14rem 0;
  background: #3077fe;
  text-align: center;
  color: #FFFFFF;
  .iconclass{
    color: #FFFFFF;
    transform: rotateZ(90deg);
  }
}
</style>
